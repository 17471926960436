<template>
  <v-menu v-model="menu" :close-on-content-click="false" bottom left offset-y min-width="300">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="white" color="primary" icon elevation="2" v-bind="attrs" v-on="on">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card class="pa-2">
      <v-card-title class="pa-4 text-subtitle-2 text-sm-subtitle-1">
        {{ keyValue == "tagName" ? "グループ" : "点検項目" }}一覧
        <v-spacer></v-spacer>
        <v-btn icon @click.stop="closeMenu()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="px-3 pb-3">
        <v-text-field
          class="text-body-2"
          :class="$vuetify.breakpoint.xs ? 'input--xs' : ''"
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          :placeholder="`${keyValue == 'tagName' ? 'グループ' : '点検項目'}名で検索`"
          clearable
          hide-details
          dense
        />
      </v-card-text>
      <v-data-table
        v-model="selected"
        :headers="[
          { text: '', value: 'selectIndex', class: 'px-0' },
          { text: 'すべて選択', value: keyValue },
        ]"
        :items="items"
        :items-per-page="-1"
        :item-key="keyValue"
        :search="search"
        no-results-text="データが見つかりません"
        disable-sort
        no-data-text="データがありません"
        show-select
        fixed-header
        hide-default-footer
        :mobile-breakpoint="null"
        height="40vh"
        dense
      >
        <template #[`header.data-table-select`]="{ props }">
          <v-simple-checkbox v-bind="props" :disabled="items.length == registeredItems.length" @click="checkAll()" />
        </template>
        <template #item="{ item, isSelected, select }">
          <tr :class="isRegistered(item) ? 'grey lighten-2' : ''" @click="isRegistered(item) || select(!isSelected)">
            <td>
              <v-simple-checkbox
                :value="isSelected"
                :disabled="isRegistered(item)"
                :indeterminate="isRegistered(item)"
                @input="select($event)"
              />
            </td>
            <td class="px-0 text--secondary" style="width: 18px">
              <span v-if="selected.some((e) => e.id == item.id)">
                {{ selected.findIndex((e) => e.id == item.id) + 1 }}
              </span>
            </td>
            <td :class="isRegistered(item) ? 'text--secondary' : ''">
              {{ item[keyValue] }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-card-actions class="px-4">
        <v-spacer></v-spacer>
        <v-btn color="primary" text :disabled="!selected.length" @click="selectItem()">リストに追加</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    registeredItems: Array,
    items: Array,
    keyValue: String,
  },
  data: () => ({
    menu: false,
    search: "",
    selected: [],
  }),
  computed: {
    isRegistered() {
      return (item) => this.registeredItems.some((e) => e.id == item.id);
    },
  },
  methods: {
    /**
     * メニューを閉じる
     */
    closeMenu() {
      this.menu = false;
      this.selected = [];
    },

    /**
     * 全選択クリック時
     */
    checkAll() {
      const allItems = this.items.filter((item) => this.registeredItems.every((e) => e.id != item.id));
      this.selected = this.selected.length == allItems.length ? [] : allItems;
    },

    /**
     * 帳票を選択する
     */
    selectItem() {
      this.$emit("select", this.selected);
      this.closeMenu();
    },
  },
};
</script>

<style scoped>
::v-deep .v-input__slot .v-input__prepend-inner {
  padding-right: 16px;
}
::v-deep .v-text-field.v-input--dense:not(.v-text-field--outlined) input::placeholder {
  font-size: 14px;
}

::v-deep .v-data-table__wrapper table thead tr th {
  padding: 0 4px;
}

::v-deep .v-data-table__wrapper table tbody tr td {
  padding: 0 4px;
}

::v-deep .v-data-table__wrapper table thead tr th:first-child {
  padding-left: 12px;
}

::v-deep .v-data-table__wrapper table tbody tr td:first-child {
  padding-left: 12px;
}

::v-deep .v-data-table__wrapper table thead tr th:last-child {
  padding-right: 12px;
}

::v-deep .v-data-table__wrapper table tbody tr td:last-child {
  padding-right: 12px;
}

::v-deep .v-input--selection-controls__ripple {
  display: none;
}
</style>
