<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card class="pa-2 pa-sm-4">
      <v-card-title class="pa-4 d-flex align-baseline text-subtitle-1 text-sm-h6">
        {{ item.id ? "グループ設定" : "グループ登録" }}
        <span class="px-4 red--text text-caption">*必須項目</span>
      </v-card-title>

      <v-form ref="form" v-model="valid">
        <v-row class="my-3 px-4" no-gutters>
          <v-col class="pr-2" cols="10" sm="6">
            <v-text-field v-model="tagName" :rules="[required, duplicated]" dense>
              <template #label>グループ名<span class="asterisk">*</span></template>
            </v-text-field>
          </v-col>
          <v-col class="pl-2" cols="10" sm="6">
            <v-text-field v-model="label" :rules="[required]" dense>
              <template #label>商品名/設定<span class="asterisk">*</span></template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>

      <!-- 入力タイプの選択 -->
      <v-row class="mb-4" no-gutters align-content="start">
        <v-col class="px-4" cols="12"><p class="mb-1 text--primary">入力タイプ</p></v-col>
        <v-col cols="12"><v-divider /></v-col>
        <v-col class="pt-4 d-flex" cols="12" style="height: 250px">
          <div class="pl-4 pr-6 py-2">
            <v-radio-group class="ma-0 pa-0" v-model="type" mandatory hide-details dense>
              <v-radio v-for="(t, i) in types" :key="i" :label="t.text" :value="t.value" />
            </v-radio-group>
          </div>
          <v-divider vertical />
          <div class="pl-6 pr-4 py-2" style="flex: 1">
            <template v-if="type == 'show'">
              <p>テキストを表示します</p>
              <v-textarea
                class="text-body-2"
                v-model="typeData[type].content"
                label="表示するテキストを入力"
                no-resize
                hide-details
                rows="4"
              />
            </template>
            <template v-if="type == 'chipSelect'">
              <p>表示された一覧から選択します</p>
              <v-checkbox class="mb-2" v-model="typeData[type].multiple" label="複数選択する" hide-details dense />
              <v-text-field
                v-model="typeData[type].text"
                counter="20"
                label="選択肢を追加"
                append-icon="mdi-plus"
                :error-messages="addTextError"
                @keydown.enter="addItem(typeData[type].items, typeData[type].text)"
                @click:append="addItem(typeData[type].items, typeData[type].text)"
              />
              <p class="mt-2 mb-1 text-body-2">選択肢</p>
              <v-chip
                v-for="(e, i) in typeData[type].items"
                :key="i"
                close
                small
                @click:close="typeData[type].items.splice(i, 1)"
              >
                {{ e }}
              </v-chip>
            </template>
            <template v-if="type == 'text'">
              <p>テキストを入力できます</p>
              <v-text-field class="text-body-2" v-model="typeData[type].placeholder" label="入力ヒント" />
            </template>
            <template v-if="type == 'number'">
              <p>テンキーを表示して数値を入力できます</p>
              <v-checkbox v-model="typeData[type].isShowMinus" label="負数入力をする" hide-details dense />
              <div class="input-digit mt-6 d-flex">
                <v-text-field v-model="typeData[type].unit" label="単位" placeholder="例：kg" hide-details />
                <v-select v-model="typeData[type].digit" :items="[1, 2, 3, 4, 5]" label="整数の最大桁数" hide-details />
                <v-select v-model="typeData[type].decimalDigit" :items="[1, 2]" label="小数の最大桁数" hide-details />
              </div>
            </template>
            <template v-if="type == 'date'">
              <p>日付を入力できます</p>
            </template>
            <template v-if="type == 'time'">
              <p>時間を入力できます</p>
              <v-checkbox v-model="typeData[type].currentTime" label="初期表示を現在の時刻にする" hide-details dense />
              <p class="mt-6 mb-2 text--secondary text-body-2">表示形式</p>
              <v-radio-group class="ma-0 pa-0" v-model="typeData[type].timeFormat" mandatory hide-details dense>
                <v-radio label="時分秒" value="HH:mm:ss" />
                <v-radio label="時分" value="HH:mm" />
                <v-radio label="分秒" value="mm:ss" />
              </v-radio-group>
            </template>
          </div>
        </v-col>
      </v-row>

      <!-- 表示例 -->
      <v-row no-gutters class="mb-8">
        <v-col class="px-4" cols="12"><p class="mb-1 text--primary">表示例</p></v-col>
        <v-col class="mb-4" cols="12"><v-divider /></v-col>
        <v-col class="px-4 d-flex text-body-2" :class="type == 'text' ? 'flex-column' : 'align-center'" cols="12">
          <span class="pt-2 pb-1">{{ label ? label : "商品名" }}</span>
          <span v-if="type == 'show'">：</span>
          <v-spacer v-if="type != 'show' && type != 'text'" />
          <div :class="type != 'text' ? 'ml-4' : ''">
            <InputResult :type="type" :data="typeData[type]" />
          </div>
        </v-col>
        <v-col class="px-2" cols="12"><v-divider /></v-col>
      </v-row>

      <v-card-actions>
        <div class="text-caption text--secondary">
          <p class="mb-0" v-if="item.updatedAt">更新日：{{ formatDate(item.updatedAt, "YYYY/MM/DD") }}</p>
          <p class="mb-0" v-if="item.updaterName">更新者：{{ item.updaterName }}</p>
        </div>
        <v-spacer></v-spacer>
        <v-btn class="text--secondary" text @click="dialog = false">閉じる</v-btn>
        <v-btn color="primary" depressed :loading="processing" :disabled="!valid || processing" @click="registItem()">
          登録
        </v-btn>
      </v-card-actions>
    </v-card>
    <DialogMessage :dialog="messageDialog" :message="message" @close="messageDialog = false" />
  </v-dialog>
</template>

<script>
import { db } from "@/plugins/firebase";
import calcDate from "cumin-common/src/mixins/calcDate";
import InputResult from "../organisms/InputResult";

export default {
  components: {
    InputResult,
  },
  props: {
    settings: Array,
    updateDB: Function,
  },
  mixins: [calcDate],
  data: () => ({
    dialog: false,
    valid: false,
    required: (v) => !!v || "必須項目です",
    item: {},
    tagName: "",
    label: "",
    type: "show",
    types: [
      // { text: "表示のみ", value: "show" },
      { text: "一覧から選択", value: "chipSelect" },
      { text: "テキスト入力", value: "text" },
      { text: "数値入力", value: "number" },
      { text: "日付入力", value: "date" },
      { text: "時間入力", value: "time" },
    ],
    typeData: { show: { content: "" } },
    addTextError: "",
    processing: false,
    message: "",
    messageDialog: false,
  }),
  computed: {
    duplicated() {
      return (value) =>
        !this.settings[1].items.some((e) => e.tagName == value && e.id != this.item.id) || "すでに使用されています";
    },
  },
  methods: {
    /**
     * ダイアログを開き、選択された行の情報を取得
     * @param {item} item
     */
    async open(item) {
      this.item = item ? JSON.parse(JSON.stringify(item)) : {};
      this.tagName = item ? item.tagName : "";
      this.label = item ? item.label : "";
      this.type = item ? item.type : "show";
      this.typeData = {
        show: {
          content: item?.content ? item.content : "",
        },
        number: {
          isShowMinus: item?.isShowMinus ? item.isShowMinus : false,
          unit: item?.unit ? item.unit : "",
          digit: item?.digit ? item.digit : 5,
          decimalDigit: item?.decimalDigit ? item.decimalDigit : 2,
          result: "",
        },
        chipSelect: {
          text: "",
          multiple: !!item?.multiple,
          items: item?.items ? item.items : [],
          result: [],
        },
        text: {
          linefeed: item?.linefeed ? item.linefeed : false,
          placeholder: item?.placeholder ?? "",
          result: "",
        },
        time: {
          timeFormat: item?.timeFormat ? item.timeFormat : "HH:mm:ss",
          currentTime: item?.currentTime ? item.currentTime : false,
          result: "0:00:00",
        },
        date: {
          result: "",
        },
      };
      this.dialog = true;
      if (!this.item.id) this.$nextTick(() => this.$refs.form.reset());
    },

    /**
     * 入力タイプ「選択」の時、選択肢に追加
     * @param {Array} items
     * @param {value} value
     */
    addItem(items, value) {
      this.addTextError = "";
      if (!value) this.addTextError = "入力してください";
      if (value.length > 20) this.addTextError = "20文字以内で入力してください";
      if (items.includes(value)) this.addTextError = "すでに登録されています";
      if (this.addTextError == "") items.push(value);
    },

    /**
     * 編集内容をDBに登録
     */
    async registItem() {
      this.processing = true;

      const shop = this.$store.getters.getShop;
      const user = this.$store.getters.getUser;
      const isCreate = !this.item.id;

      const registId = this.item.id || db.collection("coreThermoCheckTags").doc().id;
      const registData = {
        shopUID: shop.shopUID,
        tagName: this.tagName,
        label: this.label,
        type: this.type,
        updatedAt: new Date(),
        updaterName: user.name,
      };

      // 入力タイプ別に設定追加
      const data = this.typeData[this.type];
      switch (this.type) {
        case "show":
          registData.content = data.content;
          break;
        case "chipSelect":
          registData.multiple = data.multiple;
          registData.items = data.items;
          break;
        case "text":
          registData.placeholder = data.placeholder;
          break;
        case "number":
          registData.isShowMinus = data.isShowMinus;
          registData.unit = data.unit;
          registData.digit = data.digit;
          registData.decimalDigit = data.decimalDigit;
          break;
        case "time":
          registData.currentTime = data.currentTime;
          registData.timeFormat = data.timeFormat;
          break;
      }

      if (isCreate) registData.createdAt = new Date();

      const isSuccess = await this.updateDB({
        method: isCreate ? "set" : "update",
        collection: "coreThermoCheckTags",
        docId: registId,
        data: registData,
      });

      this.processing = false;
      if (isSuccess) this.dialog = false;
    },
  },
};
</script>

<style scoped>
.asterisk {
  margin-left: 2px;
  color: red;
  font-weight: 700;
}

::v-deep .v-label {
  font-size: 14px;
}

::v-deep .v-radio .v-label {
  color: #000000de;
}

::v-deep .input-digit .v-input:nth-child(-n + 2) {
  margin-right: 8px;
}
</style>
