<template>
  <v-dialog v-model="dialog" persistent width="400" max-width="500">
    <v-card class="pa-2 pa-sm-4">
      <v-card-title class="pa-4">
        <span class="text-subtitle-1 text-sm-h6">{{ title }}</span>
      </v-card-title>
      <v-card-text class="px-4 pb-4">{{ text }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="text--secondary" text @click="dialog = false">閉じる</v-btn>
        <v-btn
          class="white--text"
          color="#b71c1c"
          depressed
          :loading="processing"
          :disabled="processing"
          @click="confirmed()"
        >
          削除
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    method: Function,
  },
  data: () => ({
    dialog: false,
    docId: "",
    collection: "",
    title: "",
    text: "",
    processing: false,
  }),
  methods: {
    /**
     * ダイアログを開き、選択された行の情報を取得
     * @param {string} docId
     * @param {string} collection
     * @param {string} title
     * @param {string} text
     */
    async open({ docId, collection, title, text }) {
      this.docId = docId;
      this.collection = collection;
      this.title = title;
      this.text = text;
      this.dialog = true;
    },

    /**
     * 削除処理
     */
    async confirmed() {
      this.processing = true;

      const isSuccess = await this.method({
        method: "delete",
        collection: this.collection,
        docId: this.docId,
      });

      this.processing = false;
      if (isSuccess) this.dialog = false;
    },
  },
};
</script>
