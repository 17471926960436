<template>
  <div class="text-caption text-sm-body-2">
    <div class="px-4 pb-4">
      <div class="d-flex">
        <div>
          <p class="d-flex">
            <span class="text-no-wrap">帳票名：</span>
            <span>{{ result.sheetName }}</span>
          </p>
          <p>
            <span>点検結果：</span>
            <span :class="result.isNormalForReport ? '' : 'red--text font-weight-bold'">{{
              result.isNormalForReport ? "OK" : "NG"
            }}</span>
          </p>
        </div>
        <v-spacer></v-spacer>
        <slot />
      </div>
      <p class="d-flex">
        <span class="text-no-wrap">送信日時：</span>
        <span>{{ formatDate(result.sentAt, "YYYY/MM/DD HH:mm") }}（{{ result.sender }}）</span>
      </p>
      <p class="d-flex">
        <span class="text-no-wrap">確認日時：</span>
        <span>
          <span>{{ formatDate(result.confirmedAt, "YYYY/MM/DD HH:mm") }}</span>
          <span v-if="result.confirmerName">（{{ result.confirmerName }}）</span>
        </span>
      </p>
      <p class="d-flex">
        <span class="text-no-wrap">承認日時：</span>
        <span>
          <span>{{ formatDate(result.approvedAt, "YYYY/MM/DD HH:mm") }}</span>
          <span v-if="result.approverName">（{{ result.approverName }}）</span>
        </span>
      </p>
    </div>

    <p class="mb-1 pl-4">グループ情報</p>
    <v-card class="pa-2 mb-4" outlined>
      <v-data-table
        :headers="headers"
        :items="result.tags.filter((e) => !!e.content)"
        :items-per-page="-1"
        no-data-text="グループ情報がありません"
        disable-sort
        hide-default-header
        hide-default-footer
        :mobile-breakpoint="null"
        dense
      >
        <template #[`item.content`]="{ item }">
          <div class="d-flex align-center">
            <div style="min-width: 100px">{{ item.label }}</div>
            <v-spacer class="px-2" />
            <span>{{ item.content }}</span>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <p class="mb-1 pl-4">詳細結果</p>
    <v-card class="pa-2 mb-4" outlined>
      <v-data-table
        :headers="headers"
        :items="result.contents"
        :items-per-page="-1"
        no-data-text="点検結果がありません"
        disable-sort
        hide-default-footer
        :mobile-breakpoint="null"
        dense
      >
        <template #[`header.content`]="{}">
          <div class="d-flex">
            <span>点検内容</span>
            <v-spacer></v-spacer>
            <div class="text-center" style="width: 100px">点検結果</div>
          </div>
        </template>
        <template #[`item.content`]="{ item }">
          <div class="d-flex py-1">
            <div class="mr-4 text-no-wrap">{{ item.content }}</div>
            <v-spacer v-if="item.type != 'text'"></v-spacer>
            <v-sheet
              class="transparent d-flex align-center"
              :class="item.type == 'text' ? 'pb-2' : 'justify-center'"
              :min-width="item.type != 'text' ? 100 : ''"
              style="white-space: pre-wrap"
            >
              <v-icon v-if="item.type == 'checkbox'">{{ item.checkResult ? "mdi-check" : "" }}</v-icon>
              <span v-else-if="item.checkResult === ''" class="grey--text">{{
                item.type == "text" ? "未記入" : "未点検"
              }}</span>
              <span v-else :class="isNormalResult(item) ? '' : ngClass">{{ item.checkResult }}{{ item.unit }}</span>
            </v-sheet>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import calcDate from "cumin-common/src/mixins/calcDate";

export default {
  props: {
    result: Object,
  },
  mixins: [calcDate],
  data: () => ({
    headers: [{ text: "点検内容", value: "content" }],
    ngClass: "red--text font-weight-bold",
  }),
  computed: {
    /**
     * OK/NG判定
     * @param {object} item
     * @return {boolean} 判定結果
     */
    isNormalResult() {
      return (item) => {
        if (item.checkResult === "") return true;
        if (item.type === "okng" && item.checkResult == "NG") return false;
        if (item.type === "number" && item.checkResult !== "") {
          if (item.min !== "" && item.min > item.checkResult) return false;
          if (item.max !== "" && item.max < item.checkResult) return false;
        }
        return true;
      };
    },
  },
};
</script>

<style scoped>
.v-application p {
  margin-bottom: 0;
}

::v-deep .v-data-table__wrapper table thead tr th {
  padding: 0 8px;
  height: 42px !important;
}
::v-deep .v-data-table__wrapper table tbody tr td {
  padding: 0 8px;
}
</style>
