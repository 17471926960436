<template>
  <v-sheet class="transparent d-flex" :class="`justify-${justify}`" width="100%">
    <!-- テキスト表示のみ -->
    <div v-if="type == 'show'" class="d-flex align-center" style="white-space: pre-wrap; word-break: break-word">
      <span class="text-body-2 text--secondary">{{ data.content }}</span>
    </div>

    <!-- OK・NG -->
    <template v-if="type == 'okng'">
      <div :class="!data.required || 'pr-9'">
        <InputButtonToggle :value.sync="data.result" :required="data.required" />
      </div>
    </template>

    <!-- チェックボックス -->
    <template v-if="type == 'checkbox'">
      <div class="px-10">
        <v-simple-checkbox v-model="data.result" color="primary" hide-details />
      </div>
    </template>

    <!-- プルダウンで選択 -->
    <template v-if="type == 'select'">
      <v-select
        v-model="data.result"
        :items="data.items"
        :rules="[data.required ? (v) => !!v : true]"
        :clearable="!data.required"
        validate-on-blur
        menu-props="auto"
        no-data-text="選択肢がありません"
        hide-details
        dense
        height="32"
        style="min-width: 150px; max-width: 150px"
      />
    </template>

    <!-- 複数選択 -->
    <template v-if="type == 'chipSelect'">
      <div class="py-2 d-flex flex-wrap">
        <v-chip
          v-for="(e, i) in data.items"
          :key="`items_${i}`"
          small
          :color="data.result.includes(e) ? 'primary' : ''"
          @click="
            data.multiple
              ? data.result.includes(e)
                ? data.result.splice(data.result.indexOf(e), 1)
                : data.result.push(e)
              : data.result.includes(e)
              ? (data.result = [])
              : (data.result = [e])
          "
        >
          {{ e }}
        </v-chip>
      </div>
    </template>

    <!-- テキスト入力 -->
    <template v-if="type == 'text'">
      <v-textarea
        v-model="data.result"
        :rules="[data.required ? (v) => !!v : true]"
        :placeholder="data.placeholder"
        validate-on-blur
        full-width
        height="80"
        no-resize
        hide-details
        dense
      />
    </template>

    <!-- 数値入力 -->
    <template v-if="type == 'number'">
      <InputNumber
        :menuProps="{
          left: true,
          offsetX: true,
          transition: 'slide-y-transition',
        }"
        :value.sync="data.result"
        :required="data.required"
        :unit="data.unit"
        :min="data.min"
        :max="data.max"
        :digit="data.digit"
        :decimalDigit="data.decimalDigit"
        height="36"
        width="150"
      />
    </template>

    <!-- 日付入力 -->
    <template v-if="type == 'date'">
      <InputDate
        :menuProps="{
          left: true,
          offsetX: true,
          transition: 'slide-y-transition',
        }"
        :value.sync="data.result"
        :required="data.required"
        :showToday="true"
        height="36"
        width="150"
      />
    </template>

    <!-- 時間入力 -->
    <div v-if="type == 'time'" class="py-2">
      <InputTime :value.sync="data.result" :currentTime="data.currentTime" :timeFormat="data.timeFormat" />
    </div>
  </v-sheet>
</template>

<script>
export default {
  props: {
    justify: { type: String, default: "start" },
    outlined: { type: Boolean, default: false },
    type: { type: String, default: "show" },
    data: Object,
  },
};
</script>
