<template>
  <v-menu v-model="menu" :close-on-content-click="false" bottom left offset-y max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon>
        <v-icon>mdi-text-box-search-outline</v-icon>
      </v-btn>
    </template>
    <v-card class="pa-2 pa-sm-4" color="#f5f5f5">
      <v-card-title class="pa-4">
        <span class="text-subtitle-2 text-sm-subtitle-1">登録済帳票からコピーする</span>
        <v-spacer></v-spacer>
        <v-btn icon @click.stop="closeMenu()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card class="pa-2" outlined>
        <v-data-table
          :class="$vuetify.breakpoint.xs ? 'table--xs' : ''"
          v-model="selected"
          :headers="headers"
          :items="items"
          :items-per-page="-1"
          item-key="sheetName"
          disable-sort
          no-data-text="データがありません"
          show-select
          single-select
          fixed-header
          hide-default-footer
          :mobile-breakpoint="null"
          height="40vh"
          dense
          @click:row="(value, row) => row.select(!row.isSelected)"
        >
          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
            <v-simple-checkbox color="primary" :ripple="false" :value="isSelected" @input="select($event)" />
          </template>
          <template v-slot:[`item.tagCount`]="{ item }">{{ item.tagUID.length }}</template>
          <template v-slot:[`item.contentCount`]="{ item }">{{ item.contentUID.length }}</template>
        </v-data-table>
      </v-card>
      <v-card-actions class="pt-4">
        <v-spacer></v-spacer>
        <v-btn color="primary" text :disabled="!selected.length" @click="selectItem()">コピー</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    items: Array,
  },
  data: () => ({
    menu: false,
    selected: [],
    headers: [
      { text: "帳票名", value: "sheetName" },
      { text: "グループ数", value: "tagCount", align: "end", width: 80 },
      { text: "点検項目数", value: "contentCount", align: "end", width: 80 },
    ],
  }),
  methods: {
    /**
     * メニューを閉じる
     */
    closeMenu() {
      this.menu = false;
      this.selected = [];
    },

    /**
     * 帳票を選択する
     */
    selectItem() {
      this.$emit("select", this.selected[0]);
      this.closeMenu();
    },
  },
};
</script>

<style scoped>
::v-deep .v-data-table__wrapper table thead tr th {
  padding: 0 8px;
  height: 48px !important;
}

::v-deep .v-data-table__wrapper table tbody tr td {
  padding: 0 8px;
}

.table--xs ::v-deep .v-data-table__wrapper table thead tr th,
.table--xs ::v-deep .v-data-table__wrapper table tbody tr td {
  font-size: 12px !important;
}
</style>
