<template>
  <v-container class="pa-2 pa-sm-4" fluid style="height: 100%">
    <v-row class="px-4" no-gutters style="height: 60px">
      <v-col align-self="center">
        <span class="mr-5 text-subtitle-1 text-sm-h6">中心温度点検票</span>
        <span class="text-caption text-sm-subtitle-1 text--secondary">{{ businessDate }}</span>
      </v-col>
      <v-col class="d-flex justify-end align-center" cols="4">
        <DialogTotalResult :results="results" :groups="groups" :date="businessDate" />
        <MenuSheetSelect :sheets="sheets" :groups="groups" @select="addToList" />
      </v-col>
    </v-row>

    <!-- 点検票 -->
    <v-row class="mb-4" no-gutters :style="{ 'min-height': `${getSheetHeight}px` }">
      <v-col>
        <div class="pa-4" v-if="checkSheets.length == 0">
          <span class="text-body-2 text--secondary">右上の＋ボタンから帳票を追加して点検してください</span>
        </div>
        <v-card class="mb-2" color="#f8f8f8" v-for="(sheet, i) in checkSheets" :key="i">
          <v-card-title>
            <span class="text-subtitle-2 text-sm-subtitle-1">{{ sheet.sheetName }}</span>
            <v-spacer></v-spacer>
            <MenuComment :item="sheet" targetKey="sheetName" />
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ml-2" v-bind="attrs" v-on="on" icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-card class="py-2">
                <v-btn text @click="checkSheets.splice(i, 1)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                  <span class="ml-2">一覧から削除</span>
                </v-btn>
              </v-card>
            </v-menu>
          </v-card-title>

          <!-- グループ部分 -->
          <v-card-text class="px-2">
            <div v-for="(tag, tagIndex) in sheet.tags" :key="`${i}_${tagIndex}`">
              <!-- 送信済の場合 -->
              <template v-if="sheet.isSent">
                <p v-if="tag.content" class="mb-0 mx-2 d-flex" :key="tag.id">{{ tag.label }}：{{ tag.content }}</p>
              </template>

              <!-- 新規帳票 -->
              <div
                v-else
                class="px-2 d-flex text-body-2 text--primary"
                :class="tag.type == 'show' ? 'py-2' : tag.type == 'text' ? 'flex-column' : 'align-center'"
                style="min-height: 34px; border-bottom: solid 1px #0000001f"
              >
                <span :class="tag.type == 'text' ? 'pt-2 pb-1' : ''">{{ tag.label }}</span>
                <span v-if="tag.type == 'show'">：</span>
                <v-spacer v-if="tag.type != 'show' && tag.type != 'text'" />
                <div>
                  <InputResult :type="tag.type" :data="tag" />
                </div>
              </div>
            </div>
          </v-card-text>

          <!-- 点検項目 -->
          <v-form ref="form">
            <v-data-table
              :headers="headers"
              :items="sheet.contents"
              :items-per-page="-1"
              no-data-text="点検項目が設定されていません。"
              disable-sort
              hide-default-footer
              :mobile-breakpoint="null"
              dense
            >
              <template v-slot:[`item`]="{ item }">
                <tr :class="rowBackColor(item, sheet.existsEmpty)">
                  <td>
                    <div class="d-flex" :class="item.type == 'text' ? 'flex-column' : 'align-center'">
                      <div class="pt-2 pb-1">
                        <span>{{ item.content }}</span>
                        <v-chip v-if="item.required" class="ml-1 px-1" outlined color="required" x-small>必須</v-chip>
                      </div>
                      <v-spacer></v-spacer>
                      <v-sheet
                        class="transparent"
                        :class="item.type == 'text' ? 'pb-2' : ''"
                        :min-width="item.type != 'text' ? 150 : '100%'"
                      >
                        <InputResult justify="center" :type="item.type" :data="item" />
                      </v-sheet>
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-form>

          <v-divider></v-divider>
          <div class="px-4 py-2">
            <span v-if="sheet.existsEmpty" class="required--text text-body-2">未入力項目があります</span>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- 特記事項・送信ボタン -->
    <div class="d-sm-flex align-center">
      <CardComments
        ref="comments"
        :date="businessDate"
        :isMonthly="false"
        serviceName="中心温度"
        @load="existsComment = $event.length > 0"
      />
      <ButtonSendResult
        :loading="sending"
        :disabled="checkSheets.length == 0 || sending"
        :isNormal="checkSheets.every((sheet) => sheet.contents.every((content) => isNormalResult(content)))"
        @click="sendCheckResult()"
      />
    </div>

    <DialogSendError ref="sendErrorDialog" />
    <DialogMessage
      :dialog="messageDialog"
      :message="message"
      :messageText="messageText"
      @close="messageDialog = false"
    />
  </v-container>
</template>

<script>
import moment from "moment";
import firebase from "../../plugins/firebase";
import calcDate from "cumin-common/src/mixins/calcDate";
import dbProcess from "cumin-common/src/mixins/dbProcess";
import uploadStorage from "cumin-common/src/mixins/uploadStorage";
import DialogTotalResult from "../organisms/DialogTotalResult";
import MenuSheetSelect from "../organisms/MenuSheetSelect";
import InputResult from "../organisms/InputResult";

export default {
  components: {
    DialogTotalResult,
    MenuSheetSelect,
    InputResult,
  },
  mixins: [calcDate, dbProcess, uploadStorage],
  data: () => ({
    timerID: null,
    loading: false,
    businessDate: "",
    sheets: [],
    tags: [],
    contents: [],
    checkSheets: [],
    groups: [],
    results: [],
    headers: [{ text: "点検項目", value: "content" }],
    existsComment: false,
    sending: false,
    message: "",
    messageText: "",
    messageDialog: false,
  }),
  created: function () {
    this.$emit("created");
    this.logEvent("app_connect");
    this.businessDate = this.calculateBusinessDate(new Date());
  },
  activated: async function () {
    await this.loadSheets();
    this.loadResults();

    const setDate = () => (this.businessDate = this.calculateBusinessDate(new Date()));
    this.timerID = setInterval(setDate, 60000);
  },
  deactivated: function () {
    clearInterval(this.timerID);
  },
  computed: {
    /**
     * 点検票エリアの高さを取得
     * @return {number} 高さ
     */
    getSheetHeight() {
      const bp = this.$vuetify.breakpoint;
      const offset = this.existsComment ? 186 : 60;
      const height = bp.height - (bp.xs ? 212 : 204) - offset;
      return height <= 300 ? 300 : height;
    },

    /**
     * OK/NG判定
     * @param {object} item
     * @return {boolean} 判定結果
     */
    isNormalResult() {
      return (item) => {
        if (item.result === "") return true;
        if (item.type === "okng" && item.result == "NG") return false;
        if (item.type === "number") {
          if (item.min !== "" && item.min > item.result) return false;
          if (item.max !== "" && item.max < item.result) return false;
        }
        return true;
      };
    },

    /**
     * 点検行の色を取得
     * @param {object} item 点検項目情報
     * @param {boolean} existsEmpty 未点検有無
     * @return {string} クラス名
     */
    rowBackColor() {
      return (item, existsEmpty) => {
        if (existsEmpty && item.required && item.result === "") return "bg-required";
        if (!this.isNormalResult(item)) return "red lighten-5";
        return "white";
      };
    },
  },
  methods: {
    /**
     * DBから帳票一覧取得
     */
    async loadSheets() {
      const shop = this.$store.getters.getShop;

      this.sheets = await this.getQueryDoc({
        collection: "coreThermoCheckSheets",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
        order: [{ fieldPath: "sheetName", directionStr: "asc" }],
      });

      this.tags = await this.getQueryDoc({
        collection: "coreThermoCheckTags",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
      });
      this.tags = this.tags.map((tag) => ({
        ...tag,
        menuOption: { offsetX: true },
        result: tag.type == "chipSelect" ? [] : "",
      }));

      this.contents = await this.getQueryDoc({
        collection: "coreThermoCheckContents",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
      });
      this.contents = this.contents.map((content) => ({ ...content, result: content.type == "checkbox" ? false : "" }));
    },

    /**
     * DBから点検結果を取得
     */
    async loadResults() {
      const shop = this.$store.getters.getShop;
      const startAt = new Date(this.businessDate + " 00:00:00");
      const endAt = new Date(this.businessDate + " 23:59:59");

      this.results = await this.getQueryDoc({
        collection: "coreThermoCheckResults",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
        order: [{ fieldPath: "registeredAt", directionStr: "asc" }],
        startAt: startAt,
        endAt: endAt,
      });

      // 送信日時を降順でソート
      this.results.sort((a, b) => {
        const timeA = new Date(a.sentAt.seconds * 1000);
        const timeB = new Date(b.sentAt.seconds * 1000);
        if (timeA > timeB) return -1;
        if (timeA < timeB) return 1;
        return 0;
      });

      // 同じ帳票の点検結果を抽出
      this.groups = [];
      for (const result of this.results) {
        // すでに抽出済か
        if (this.groups.some((e) => e.groupUID == result.groupUID)) continue;

        // 未抽出の場合、同じ点検結果の数を集計
        const checkCount = this.results.filter((e) => e.groupUID == result.groupUID).length;
        this.groups.push({ ...result, checkCount });
      }
    },

    /**
     * 帳票一覧から点検リストに追加
     * @param {array} items
     * @param {boolean} isSent
     */
    async addToList({ items, isSent }) {
      const tags = JSON.parse(JSON.stringify(this.tags));
      const contents = JSON.parse(JSON.stringify(this.contents));
      for (const item of items) {
        if (this.checkSheets.some((sheet) => sheet.id == item.id)) continue;

        this.checkSheets.push({
          ...item,
          isSent,
          tags: isSent
            ? item.tags.map((e) => ({ ...e, id: e.uid }))
            : item.tagUID
                .filter((uid) => !!tags.find((tag) => tag.id == uid))
                .map((uid) => JSON.parse(JSON.stringify(tags.find((tag) => tag.id == uid)))),
          contents: isSent
            ? item.contents.map((e) => ({
                ...e,
                id: e.uid,
                result: e.referable ? e.checkResult : e.type == "checkbox" ? false : "",
              }))
            : item.contentUID
                .filter((uid) => !!contents.find((content) => content.id == uid))
                .map((uid) => JSON.parse(JSON.stringify(contents.find((content) => content.id == uid)))),
          result: "",
        });
      }
    },

    /**
     * 点検結果送信
     */
    async sendCheckResult() {
      // オフライン時の処理
      if (!navigator.onLine) return this.$refs.sendErrorDialog.open("offline");

      this.sending = true;

      const user = this.$store.getters.getUser;
      const shop = this.$store.getters.getShop;
      const sendDate = new Date(this.businessDate + " 00:00:00");
      const registeredAt = firebase.firestore.Timestamp.fromDate(sendDate);
      const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

      const commonData = {
        userUID: user.userUID,
        shopUID: shop.shopUID,
        registeredAt,
        sentAt: serverTimestamp,
        sender: user.name,
        confirmedAt: "",
        confirmerName: "",
        approvedAt: "",
        approverName: "",
        createdAt: serverTimestamp,
        updatedAt: serverTimestamp,
      };

      // 点検結果をループ
      const ngItems = [];
      const sentIds = [];
      const writeItems = [];
      for (const sheet of this.checkSheets) {
        // 必須入力バリデーション
        this.$set(sheet, "existsEmpty", false);
        if (sheet.contents.some((e) => e.result === "" && e.required)) {
          this.$set(sheet, "existsEmpty", true);
          continue;
        }

        // グループの保存
        const tags = sheet.tags.map((e) => {
          let content = "";
          if (sheet.isSent) {
            content = e.content;
          } else {
            content = e.result;
            if (e.type == "show") content = e.content;
            if (e.type == "chipSelect") content = e.result.join();
            if (e.type == "number" && e.result !== "") content = `${Number(e.result)}${e.unit ? e.unit : ""}`;
          }
          return { uid: sheet.isSent ? e.uid : e.id, label: e.label, type: e.type, content };
        });

        // 点検項目の保存
        const contents = sheet.contents.map((e) => {
          const content = {
            uid: e.id,
            content: e.content,
            type: e.type,
            referable: e.referable,
            checkResult: e.result ?? "",
          };
          if (e.required !== undefined) content.required = e.required;
          if (e.items !== undefined) content.items = e.items;
          if (e.placeholder !== undefined) content.placeholder = e.placeholder;
          if (e.type == "number") {
            content.checkResult = e.result !== "" ? Number(e.result) : "";
            content.isShowMinus = !!e.isShowMinus;
            content.unit = e.unit ? e.unit : "";
            content.digit = e.digit ? e.digit : 5;
            content.decimalDigit = e.decimalDigit ? e.decimalDigit : 2;
            content.min = e.min ? e.min : "";
            content.max = e.max ? e.max : "";
          }
          if (e.type == "time") {
            content.currentTime = !!e.currentTime;
            content.timeFormat = e.timeFormat;
          }
          return content;
        });

        // 送信結果データ作成
        const resultId = this.createDocId("coreThermoCheckResults");
        const sendData = {
          ...commonData,
          sheetName: sheet.sheetName,
          groupUID: sheet.isSent ? sheet.groupUID : resultId,
          isNormalForReport: sheet.contents.every((content) => this.isNormalResult(content)),
          tags,
          contents,
        };

        writeItems.push({
          method: "set",
          collection: "coreThermoCheckResults",
          docId: resultId,
          data: sendData,
        });

        // 特記事項がある場合
        if (sheet.comment || sheet.imgFileUrl) {
          const commentId = this.createDocId("comments");
          const sendCommentData = {
            name: user.name,
            registrantUID: user.userUID,
            shopUID: shop.shopUID,
            position: user.position,
            content: sheet.comment ? sheet.comment : "",
            serviceName: "中心温度",
            relationCheckResult: {
              uid: resultId,
              title: sendData.sheetName,
              isNormal: sendData.isNormalForReport,
              sentAt: moment().format("MM/DD HH:mm"),
            },
            registeredAt,
            createdAt: serverTimestamp,
            updatedAt: serverTimestamp,
          };

          // 画像ありの場合、画像をアップロードする
          if (sheet.compressedFile) {
            const commentDate = this.businessDate.replace(/\//g, "-");
            const path = `commentsFile/${shop.shopUID}/${commentDate}/${commentId}.jpg`;
            const uploadResult = await this.uploadFile({ path, file: sheet.compressedFile });

            // 送信失敗の場合
            if (uploadResult.status == "error") {
              const type = uploadResult.code === "storage/retry-limit-exceeded" ? "unstable" : "unexpected";
              this.$refs.sendErrorDialog.open(type);
              this.sending = false;
              return;
            }

            sendCommentData.imgFileURL = uploadResult.url;
          }

          writeItems.push({
            method: "set",
            collection: "comments",
            docId: commentId,
            data: sendCommentData,
          });
        }

        // NGリストに追加
        if (!sendData.isNormalForReport) ngItems.push(sendData);

        sentIds.push(sheet.id);
      }

      if (sentIds.length == 0) return (this.sending = false);

      // DB登録
      const result = await this.writeTransaction(writeItems);

      // 登録失敗の場合
      if (result.status !== "success") {
        const type = result.message == "Connection failed." ? "unstable" : "unexpected";
        this.$refs.sendErrorDialog.open(type);
        this.logEvent("send_check_list", { message: "点検票の送信に失敗しました。" });
        this.sending = false;
        return;
      }

      // 特記事項リストの更新
      const isSentComment = this.checkSheets.some((e) => !!e.comment || !!e.compressedFile);
      if (isSentComment) this.$refs.comments.loadComment();

      // 点検結果の更新
      this.loadResults();

      // 点検票リストの更新
      this.checkSheets = this.checkSheets.filter((sheet) => !sentIds.includes(sheet.id));

      this.message = "送信しました。";
      this.messageText = "点検数：" + sentIds.length;
      this.messageDialog = true;
      this.logEvent("send_check_list", { message: this.message + this.messageText });

      this.sending = false;

      // NG点検があった場合、メール送信
      if (ngItems.length > 0) this.sendNgMail(ngItems);
    },

    /**
     * 設定温度を上回る製品があった場合、メール送信
     * @param {array} items NG点検結果
     */
    async sendNgMail(items) {
      const user = this.$store.getters.getUser;
      const shop = this.$store.getters.getShop;
      let listText = "";
      for (const item of items) listText = `${listText}帳票名：${item.sheetName}\n`;

      let environment = "";
      if (process.env.NODE_ENV == "development") environment = "[DEV]";
      if (process.env.NODE_ENV == "staging") environment = "[STG]";

      const functions = await firebase.app().functions("asia-northeast1");
      const sendMail = functions.httpsCallable("sendMail");

      const subject =
        shop.shopUID == this.$route.params.shopUID
          ? `${environment}[ハレコード] 中心温度点検結果NGのお知らせ`
          : `${environment}[ハレコード][${shop.selectShopName}] 中心温度点検結果NGのお知らせ`;

      const mailResult = await sendMail({
        position: user.position,
        shopUID: shop.shopUID,
        subject: subject,
        text:
          "各位\n\n" +
          "中心温度点検の点検結果にNGがありましたのでお知らせいたします。\n\n" +
          "【店舗名】\n" +
          "" +
          `${shop.selectShopName}\n\n` +
          "【点検結果】\n" +
          `${listText}\n\n` +
          "以下のURLからログインして内容のご確認をお願いいたします。\n" +
          `${window.location.origin}/${shop.shopUID}/check-summary\n\n` +
          "※このメールは送信専用のため、ご返信いただけません。\n\n\n" +
          "－－－－－－－－－－－－－－－－－－－－－－－－－－－－－－\n" +
          "ハレコードは株式会社ウエノフードテクノの商標です。\n",
      });

      if (mailResult.data.status == "error") {
        this.logEvent("error_function", {
          method_name: mailResult.data.method,
          error_message: mailResult.data.error.message,
        });
      }
    },
  },
};
</script>

<style scoped>
::v-deep .v-data-table .v-data-table__wrapper table thead tr th {
  height: 38px;
  background-color: #f8f8f8;
}

::v-deep .v-data-table .v-data-table__wrapper table tbody tr td {
  height: 38px;
}

.input-number ::v-deep .v-select__slot input {
  text-align: right;
}
</style>
