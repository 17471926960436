<template>
  <div>
    <v-overlay :value="menu"></v-overlay>
    <v-menu
      v-model="menu"
      transition="slide-x-reverse-transition"
      :close-on-click="false"
      :close-on-content-click="false"
      :min-width="$vuetify.breakpoint.xs ? 350 : 500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="white"
          v-bind="attrs"
          v-on="on"
          color="primary"
          icon
          elevation="2"
          :large="$vuetify.breakpoint.smAndUp"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card class="pa-2 pa-sm-4">
        <v-card-title class="pa-4">
          <span class="text-subtitle-1 text-sm-h6">帳票一覧</span>
          <v-spacer></v-spacer>
          <v-btn icon @click.stop="closeMenu()"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-radio-group class="ma-0 px-4 py-0" v-model="mode" mandatory hide-details dense>
          <v-radio value="sheets" label="帳票から選択" />
          <v-radio value="groups" label="送信済結果から選択" />
        </v-radio-group>
        <v-card-text class="pa-4">
          <v-text-field
            :class="$vuetify.breakpoint.xs ? 'input--xs text-body-2' : ''"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            placeholder="帳票名で検索"
            clearable
            hide-details
            dense
            style="width: 300px"
          />
        </v-card-text>
        <v-data-table
          :class="$vuetify.breakpoint.xs ? 'table--xs' : ''"
          v-model="selected[mode]"
          :headers="headers[mode]"
          :items="this[mode]"
          :items-per-page="-1"
          item-key="id"
          sort-by="sheetName"
          :search="search"
          no-data-text="帳票がありません"
          no-results-text="帳票が見つかりません"
          show-select
          checkbox-color="primary"
          fixed-header
          hide-default-footer
          :mobile-breakpoint="null"
          height="50vh"
          dense
          @click:row="(_, row) => row.select(!row.isSelected)"
        >
          <template v-slot:[`header.sheetName`]="{}">
            <span>帳票名</span>
            <span v-if="mode == 'groups'" class="grey--text text-caption"><br />製品名・グループ情報</span>
          </template>
          <template v-slot:[`item.sheetName`]="{ item }">
            <td class="py-2 overflow-x-auto" :style="{ 'max-width': $vuetify.breakpoint.xs ? '100px' : '270px' }">
              <p>{{ item.sheetName }}</p>
              <p
                class="grey--text text-caption text-truncate"
                v-for="(tag, i) in item.tags ? item.tags.slice(0, 5) : []"
                :key="`${item.id}_${tag.id}_${i}`"
              >
                {{ tag.label }}：{{ tag.content }}
              </p>
            </td>
          </template>
          <template v-slot:[`header.checkCount`]="{}">
            <span>本日の点検回数</span><br />
            <span class="grey--text text-caption">(最終点検時間)</span>
          </template>
          <template v-slot:[`item.checkCount`]="{ item }">
            <span class="mr-2">{{ item.checkCount }}回</span>
            <span class="grey--text text-caption">({{ formatDate(item.sentAt, "H:mm") }})</span>
          </template>
        </v-data-table>
        <v-divider></v-divider>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn color="primary" text :disabled="!selected[mode].length" @click="addItem()">リストに追加</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import calcDate from "cumin-common/src/mixins/calcDate";

export default {
  props: {
    sheets: Array,
    groups: Array,
  },
  mixins: [calcDate],
  data: () => ({
    menu: false,
    search: "",
    selected: { sheets: [], groups: [] },
    mode: "sheets",
    headers: {
      sheets: [{ text: "帳票名", value: "sheetName" }],
      groups: [
        { text: "帳票名\nグループ情報", value: "sheetName" },
        { text: "本日の点検回数\n(最終点検時間)", value: "checkCount", filterable: false, width: "134px" },
      ],
    },
  }),
  methods: {
    /**
     * メニューを閉じる
     */
    closeMenu() {
      this.menu = false;
      this.selected = { sheets: [], groups: [] };
    },

    /**
     * リストに追加
     */
    addItem() {
      this.$emit("select", { items: this.selected[this.mode], isSent: this.mode == "groups" });
      this.closeMenu();
    },
  },
};
</script>

<style scoped>
::v-deep .v-radio .v-input--selection-controls__input {
  margin-right: 16px !important;
}

::v-deep .v-label {
  font-size: 14px;
}

::v-deep .v-text-field .v-input__prepend-inner {
  padding-right: 16px;
}

::v-deep .v-data-table__wrapper table thead tr th {
  height: 42px !important;
  white-space: pre-wrap;
}

::v-deep .v-data-table__wrapper table thead tr th,
::v-deep .v-data-table__wrapper table tbody tr td {
  padding: 0 8px;
}

::v-deep .v-data-table__wrapper table thead tr th:first-child,
::v-deep .v-data-table__wrapper table tbody tr td:first-child {
  padding-left: 16px !important;
}

::v-deep .v-data-table__wrapper table thead tr th:last-child,
::v-deep .v-data-table__wrapper table tbody tr td:last-child {
  padding-right: 16px !important;
}

::v-deep .table--xs .v-data-table__wrapper table tbody tr td {
  font-size: 12px;
}

::v-deep .v-input--selection-controls__ripple {
  display: none;
}

::v-deep .v-simple-checkbox .v-input--selection-controls__input {
  margin: 0 !important;
}

p {
  margin: 0;
}
</style>
