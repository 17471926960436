var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white",attrs:{"color":"primary","icon":"","elevation":"2","large":_vm.$vuetify.breakpoint.smAndUp}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-table-eye")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-2 pa-sm-4",attrs:{"color":"#f8f8f8"}},[_c('v-card-title',{staticClass:"pa-4 d-flex align-baseline"},[_c('span',{staticClass:"mr-5 text-subtitle-1 text-sm-h6"},[_vm._v("点検状況確認")]),_c('span',{staticClass:"text-caption text-sm-subtitle-1 text--secondary"},[_vm._v(" "+_vm._s(_vm.date)+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-sheet',{staticClass:"px-4 pb-2",attrs:{"color":"#f8f8f8"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"50px"}},[_c('v-autocomplete',{staticClass:"mr-4 text-body-2",style:({ 'max-width': _vm.$vuetify.breakpoint.xs ? '300px' : '400px' }),attrs:{"items":_vm.groups,"item-text":"sheetName","item-value":"groupUID","clearable":"","placeholder":"帳票を選択","no-data-text":"送信済の帳票がありません","prepend-inner-icon":"mdi-magnify","hide-details":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2",style:({ 'max-width': _vm.$vuetify.breakpoint.xs ? '300px' : '400px' })},[_c('p',[_vm._v(_vm._s(item.sheetName))]),_vm._l((item.tags.slice(0, 5)),function(tag,i){return _c('p',{key:((tag.id) + "_" + i),staticClass:"grey--text text-caption text-truncate"},[_vm._v(" "+_vm._s(tag.label)+"："+_vm._s(tag.content)+" ")])})],2)]}}]),model:{value:(_vm.groupUID),callback:function ($$v) {_vm.groupUID=$$v},expression:"groupUID"}}),_c('v-spacer'),(_vm.groupUID)?[(_vm.isShowTagInfo)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.isShowTagInfo = false}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1):_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.isShowTagInfo = true}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]:_vm._e()],2),(_vm.groupUID && _vm.isShowTagInfo)?[_c('div',{staticClass:"pb-2 text-caption",attrs:{"no-gutters":""}},_vm._l((_vm.groupItem.tags.slice(0, 5)),function(tag,i){return _c('p',{key:((tag.id) + "_" + i),staticClass:"text-truncate"},[_vm._v(" "+_vm._s(tag.label)+"："+_vm._s(tag.content)+" ")])}),0)]:_vm._e()],2),_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.results.filter(function (e) { return e.groupUID == _vm.groupUID; }),"items-per-page":-1,"sort-by":"sentAt","disable-sort":_vm.$vuetify.breakpoint.xs,"no-data-text":"帳票を選択してください","fixed-header":"","hide-default-footer":"","mobile-breakpoint":null,"height":"50vh","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('p',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.formatDate(item.sentAt, "HH:mm")))]),(_vm.$vuetify.breakpoint.smAndUp)?_c('p',{staticClass:"grey--text",staticStyle:{"font-size":"0.5rem"}},[_vm._v(" "+_vm._s(item.sender)+" ")]):_vm._e()]),_vm._l((item.contents),function(c,i){return _c('td',{key:((item.id) + "_" + i)},[(c.type == 'checkbox' && c.checkResult)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e(),(c.type != 'checkbox')?_c('span',{class:_vm.isNormalResult(c) ? '' : 'red--text font-weight-bold'},[_vm._v(" "+_vm._s(c.checkResult)+" ")]):_vm._e(),(c.checkResult && c.unit)?_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(c.unit))]):_vm._e()],1)})],2)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }