var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"transparent d-flex",class:("justify-" + _vm.justify),attrs:{"width":"100%"}},[(_vm.type == 'show')?_c('div',{staticClass:"d-flex align-center",staticStyle:{"white-space":"pre-wrap","word-break":"break-word"}},[_c('span',{staticClass:"text-body-2 text--secondary"},[_vm._v(_vm._s(_vm.data.content))])]):_vm._e(),(_vm.type == 'okng')?[_c('div',{class:!_vm.data.required || 'pr-9'},[_c('InputButtonToggle',{attrs:{"value":_vm.data.result,"required":_vm.data.required},on:{"update:value":function($event){return _vm.$set(_vm.data, "result", $event)}}})],1)]:_vm._e(),(_vm.type == 'checkbox')?[_c('div',{staticClass:"px-10"},[_c('v-simple-checkbox',{attrs:{"color":"primary","hide-details":""},model:{value:(_vm.data.result),callback:function ($$v) {_vm.$set(_vm.data, "result", $$v)},expression:"data.result"}})],1)]:_vm._e(),(_vm.type == 'select')?[_c('v-select',{staticStyle:{"min-width":"150px","max-width":"150px"},attrs:{"items":_vm.data.items,"rules":[_vm.data.required ? function (v) { return !!v; } : true],"clearable":!_vm.data.required,"validate-on-blur":"","menu-props":"auto","no-data-text":"選択肢がありません","hide-details":"","dense":"","height":"32"},model:{value:(_vm.data.result),callback:function ($$v) {_vm.$set(_vm.data, "result", $$v)},expression:"data.result"}})]:_vm._e(),(_vm.type == 'chipSelect')?[_c('div',{staticClass:"py-2 d-flex flex-wrap"},_vm._l((_vm.data.items),function(e,i){return _c('v-chip',{key:("items_" + i),attrs:{"small":"","color":_vm.data.result.includes(e) ? 'primary' : ''},on:{"click":function($event){_vm.data.multiple
            ? _vm.data.result.includes(e)
              ? _vm.data.result.splice(_vm.data.result.indexOf(e), 1)
              : _vm.data.result.push(e)
            : _vm.data.result.includes(e)
            ? (_vm.data.result = [])
            : (_vm.data.result = [e])}}},[_vm._v(" "+_vm._s(e)+" ")])}),1)]:_vm._e(),(_vm.type == 'text')?[_c('v-textarea',{attrs:{"rules":[_vm.data.required ? function (v) { return !!v; } : true],"placeholder":_vm.data.placeholder,"validate-on-blur":"","full-width":"","height":"80","no-resize":"","hide-details":"","dense":""},model:{value:(_vm.data.result),callback:function ($$v) {_vm.$set(_vm.data, "result", $$v)},expression:"data.result"}})]:_vm._e(),(_vm.type == 'number')?[_c('InputNumber',{attrs:{"menuProps":{
        left: true,
        offsetX: true,
        transition: 'slide-y-transition',
      },"value":_vm.data.result,"required":_vm.data.required,"unit":_vm.data.unit,"min":_vm.data.min,"max":_vm.data.max,"digit":_vm.data.digit,"decimalDigit":_vm.data.decimalDigit,"height":"36","width":"150"},on:{"update:value":function($event){return _vm.$set(_vm.data, "result", $event)}}})]:_vm._e(),(_vm.type == 'date')?[_c('InputDate',{attrs:{"menuProps":{
        left: true,
        offsetX: true,
        transition: 'slide-y-transition',
      },"value":_vm.data.result,"required":_vm.data.required,"showToday":true,"height":"36","width":"150"},on:{"update:value":function($event){return _vm.$set(_vm.data, "result", $event)}}})]:_vm._e(),(_vm.type == 'time')?_c('div',{staticClass:"py-2"},[_c('InputTime',{attrs:{"value":_vm.data.result,"currentTime":_vm.data.currentTime,"timeFormat":_vm.data.timeFormat},on:{"update:value":function($event){return _vm.$set(_vm.data, "result", $event)}}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }