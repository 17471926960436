<template>
  <v-dialog v-model="dialog" persistent max-width="500" @input="openDialog()">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="isShowButton"
        class="white"
        v-bind="attrs"
        v-on="on"
        color="primary"
        icon
        elevation="2"
        :large="$vuetify.breakpoint.smAndUp"
      >
        <v-icon>mdi-download-outline</v-icon>
      </v-btn>
    </template>
    <v-card class="pa-2 pa-sm-4">
      <v-card-title class="pa-4">点検結果出力</v-card-title>
      <v-card-text class="px-2 pb-4">
        <v-row no-gutters>
          <v-col class="px-2 py-3" cols="6">
            <v-select
              v-model="format"
              :items="['csv', 'xlsx']"
              label="出力形式"
              :disabled="properties.length > 1"
              :hint="properties.length > 1 ? '点検種別が複数ある為、xlsxのみ' : ''"
              persistent-hint
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="px-2 py-3" cols="6">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              transition="slide-y-transition"
              offset-y
              left
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  :value="startAt.replaceAll('-', '/')"
                  label="取得開始期間"
                  readonly
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="startAt"
                :max="endAt"
                no-title
                color="primary"
                locale="ja-jp"
                :day-format="(date) => new Date(date).getDate()"
                @input="menu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="px-2 py-3" cols="6">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              transition="slide-y-transition"
              offset-y
              left
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  :value="endAt.replaceAll('-', '/')"
                  label="取得終了期間"
                  :error-messages="errorMessage"
                  readonly
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="endAt"
                :min="startAt"
                :max="max"
                no-title
                color="primary"
                locale="ja-jp"
                :day-format="(date) => new Date(date).getDate()"
                @input="menu2 = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <span class="red--text text-caption">※最長取得期間：1年</span>
        <v-spacer></v-spacer>
        <v-btn class="text--secondary" text @click="dialog = false">閉じる</v-btn>
        <v-btn color="primary" depressed :loading="loading" :disabled="loading" @click="exportData()">出力</v-btn>
      </v-card-actions>
    </v-card>
    <DialogMessage
      :dialog="messageDialog"
      :message="message"
      :messageText="messageText"
      @close="messageDialog = false"
    />
  </v-dialog>
</template>

<script>
import moment from "moment";
import ExcelJS from "exceljs";
import encoding from "encoding-japanese";
import dbProcess from "cumin-common/src/mixins/dbProcess";

export default {
  props: {
    serviceName: String,
    properties: Array,
    selectedDate: String,
  },
  mixins: [dbProcess],
  data: () => ({
    dialog: false,
    isShowButton: false,
    menu: false,
    menu2: false,
    format: "",
    startAt: "",
    endAt: "",
    max: "",
    errorMessage: "",
    loading: false,
    message: "",
    messageText: "",
    messageDialog: false,
  }),
  created: function () {
    const user = this.$store.getters.getUser;
    this.isShowButton = user.authority == "approver" || user.authority == "confirmer";
  },
  methods: {
    /**
     * ダイアログを開く
     */
    openDialog() {
      this.format = this.properties.length > 1 ? "xlsx" : "csv";
      const month = this.selectedDate.substr(0, 7);
      this.startAt = `${month}/01`.replaceAll("/", "-");
      const startDate = new Date(this.startAt.replaceAll("-", "/"));
      this.endAt = moment(startDate).add(1, "months").add(-1, "seconds").format("YYYY-MM-DD");
      const endDate = new Date(this.endAt.replaceAll("-", "/"));
      this.max = moment().format("YYYY-MM-DD");
      if (endDate > new Date()) this.endAt = this.max;
    },

    /**
     * 点検結果出力
     */
    async exportData() {
      this.errorMessage = "";
      const startAt = new Date(this.startAt.replaceAll("-", "/") + " 00:00:00");
      const endAt = new Date(this.endAt.replaceAll("-", "/") + " 23:59:59");
      if (Math.abs(moment(startAt).diff(endAt, "days")) > 365) {
        this.errorMessage = "取得期間が1年を超えています";
        return;
      }

      this.loading = true;

      // 出力ファイルの作成
      const workbook = new ExcelJS.Workbook();

      const shop = this.$store.getters.getShop;
      let existsResults = false;

      for (const property of this.properties) {
        // DBから結果取得
        let results = await this.getQueryDoc({
          collection: property.collection,
          where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
          order: [{ fieldPath: "registeredAt", directionStr: "asc" }],
          startAt: startAt,
          endAt: endAt,
        });
        if (results.length > 0) existsResults = true;

        // ヘッダー情報初期化
        const columns = JSON.parse(JSON.stringify(property.columns));

        // 点検結果が配列の場合
        const editResults = [];
        const resultKey = property.resultKey;
        const resultColumns = property.resultColumns;
        if (resultKey) {
          for (const result of results) {
            const tags = result.tags.map((t) => `${t.label}:${t.content}`);
            for (const item of result[resultKey]) {
              const checkItem = {};
              for (const col of resultColumns) {
                checkItem[col.key] = item[col.key.replace("result-", "")];
                if (col.key == "checkResult" && checkItem[col.key] == "") checkItem[col.key] = "未点検";
              }
              editResults.push({ ...result, ...checkItem, tags });
            }
          }

          // 列情報も挿入
          const index = columns.findIndex((e) => e.key == resultKey);
          columns.splice(index, 1, property.resultColumns);
        }
        if (editResults.length > 0) results = editResults;

        // 日付形式の変換、配列のダブルコーテーションを削除
        results.forEach((result) => {
          Object.keys(result).forEach((key) => {
            if (result[key] != undefined && result[key].seconds)
              result[key] = moment(result[key].seconds * 1000).format("YYYY/MM/DD HH:mm:ss");

            if (typeof result[key] == "object") result[key] = JSON.stringify(result[key]).replaceAll('"', "");

            if (key == "isNormalForReport") result[key] = result[key] ? "OK" : "NG";
          });
        });

        workbook.addWorksheet(property.sheetName);
        const worksheet = workbook.getWorksheet(property.sheetName);

        worksheet.columns = columns.flat();
        worksheet.addRows(results);
      }

      if (!existsResults) {
        this.message = "指定した日付の点検結果はありませんでした";
        this.messageText = "";
        this.messageDialog = true;
        this.loading = false;
        return;
      }

      const uint8Array =
        this.format === "xlsx"
          ? await workbook.xlsx.writeBuffer() //xlsxの場合
          : new Uint8Array(
              encoding.convert(await workbook.csv.writeBuffer(), {
                from: "UTF8",
                to: "SJIS",
              }) //csvの場合
            );
      const blob = new Blob([uint8Array], { type: "application/octet-binary" });
      const url = window.URL.createObjectURL(blob);
      const fileName = `${this.serviceName}_点検結果_${shop.shopName}_${this.startAt.replaceAll(
        "-",
        ""
      )}_${this.endAt.replaceAll("-", "")}.${this.format}`;
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
      a.remove();

      this.message = "点検結果を出力しました。";
      this.messageText = `ファイル名：${fileName}`;
      this.messageDialog = true;
      this.loading = false;
    },
  },
};
</script>
