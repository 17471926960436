<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <v-card class="pa-2 pa-sm-4" color="#f5f5f5">
      <v-card-title class="pa-4 d-flex align-baseline text-subtitle-1 text-sm-h6">
        {{ item.id ? "帳票編集" : "帳票登録" }}
        <span class="px-4 red--text text-caption">*必須項目</span>
      </v-card-title>

      <v-card-text class="px-0 pb-2">
        <v-form ref="form" v-model="valid">
          <v-row class="px-4 pb-4" no-gutters>
            <v-col cols="10" sm="9">
              <v-text-field v-model="item.sheetName" :rules="[required, duplicated]">
                <template #label>帳票名<span class="asterisk">*</span></template>
              </v-text-field>
            </v-col>

            <!-- 既存帳票のコピー機能 -->
            <v-col class="px-2 px-sm-4" align-self="center">
              <MenuSheetCopy :items="settings[0].items" @select="copyItem" />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="pr-1" cols="6">
              <v-card>
                <v-data-table
                  :class="$vuetify.breakpoint.xs ? 'table--xs' : ''"
                  :headers="[
                    { text: 'グループ名', value: 'tagName' },
                    { text: '', value: 'action', align: 'end', class: 'pr-1', width: 96 },
                  ]"
                  :items="tags"
                  :items-per-page="-1"
                  loading-text="読込中"
                  no-data-text="データがありません"
                  disable-sort
                  fixed-header
                  hide-default-footer
                  :mobile-breakpoint="null"
                  height="40vh"
                  dense
                >
                  <template #[`header.action`]="{}">
                    <MenuSheetPartsSelect
                      :items="settings[1].items"
                      :registeredItems="tags"
                      keyValue="tagName"
                      @select="tags.push(...$event)"
                    />
                  </template>
                  <template #body="{ items }">
                    <draggable v-model="tags" tag="tbody" handle=".drag" animation="200">
                      <tr v-for="(item, i) in items" :key="i">
                        <td>{{ item.tagName }}</td>
                        <td class="pr-2">
                          <v-btn icon @click.stop="tags.splice(i, 1)">
                            <v-icon>mdi-trash-can-outline</v-icon>
                          </v-btn>
                          <v-btn class="ml-2 drag" icon small style="cursor: move">
                            <v-icon>mdi-drag-horizontal-variant</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </draggable>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
            <v-col class="pl-1" cols="6">
              <v-card>
                <v-data-table
                  :class="$vuetify.breakpoint.xs ? 'table--xs' : ''"
                  :headers="[
                    { text: '点検内容', value: 'content' },
                    { text: '', value: 'action', align: 'end', class: 'pr-1', width: 96 },
                  ]"
                  :items="contents"
                  :items-per-page="-1"
                  loading-text="読込中"
                  no-data-text="データがありません"
                  disable-sort
                  fixed-header
                  hide-default-footer
                  :mobile-breakpoint="null"
                  height="40vh"
                  dense
                >
                  <template #[`header.content`]="{}">
                    <span>点検内容</span>
                    <span class="asterisk">*</span>
                  </template>
                  <template #[`header.action`]="{}">
                    <MenuSheetPartsSelect
                      :items="settings[2].items"
                      :registeredItems="contents"
                      keyValue="content"
                      @select="contents.push(...$event)"
                    />
                  </template>
                  <template #body="{ items }">
                    <draggable v-model="contents" tag="tbody" handle=".drag" animation="200">
                      <tr v-for="(item, i) in items" :key="i">
                        <td>{{ item.content }}</td>
                        <td class="pr-2">
                          <v-btn icon @click.stop="contents.splice(i, 1)">
                            <v-icon>mdi-trash-can-outline</v-icon>
                          </v-btn>
                          <v-btn class="ml-2 drag" icon small style="cursor: move">
                            <v-icon>mdi-drag-horizontal-variant</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </draggable>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <div class="text-caption text--secondary">
          <p class="mb-0" v-if="updatedAt">更新日：{{ updatedAt }}</p>
          <p class="mb-0" v-if="item.updaterName">更新者：{{ item.updaterName }}</p>
        </div>
        <v-spacer></v-spacer>
        <v-btn class="text--secondary" text @click="dialog = false">閉じる</v-btn>
        <v-btn
          color="primary"
          depressed
          :loading="processing"
          :disabled="!valid || contents.length === 0 || processing"
          @click="registItem()"
        >
          登録
        </v-btn>
      </v-card-actions>
    </v-card>
    <DialogMessage :dialog="messageDialog" :message="message" @close="messageDialog = false" />
  </v-dialog>
</template>

<script>
import { db } from "@/plugins/firebase";
import moment from "moment";
import Draggable from "vuedraggable";
import MenuSheetPartsSelect from "../organisms/MenuSheetPartsSelect";
import MenuSheetCopy from "./MenuSheetCopy";

export default {
  components: {
    MenuSheetPartsSelect,
    Draggable,
    MenuSheetCopy,
  },
  props: {
    settings: Array,
    updateDB: Function,
  },
  data: () => ({
    dialog: false,
    valid: false,
    required: (v) => !!v || "必須項目です",
    item: {},
    tags: [],
    contents: [],
    updatedAt: "",
    processing: false,
    message: "",
    messageDialog: false,
  }),
  computed: {
    duplicated() {
      return (value) =>
        !this.settings[0].items.some((e) => e.sheetName == value && e.id != this.item.id) || "すでに使用されています";
    },
  },
  methods: {
    /**
     * ダイアログを開き、選択された行の情報を取得
     * @param {item} item
     */
    async open(item) {
      this.item = item ? JSON.parse(JSON.stringify(item)) : { tagUID: [], contentUID: [] };
      this.updatedAt = item ? moment(item.updatedAt.seconds * 1000).format("YYYY/MM/DD") : "";
      this.tags = [];
      this.contents = [];

      for (const uid of this.item.tagUID) {
        const tag = this.settings[1].items.find((e) => e.id == uid);
        if (tag) this.tags.push(tag);
      }

      for (const uid of this.item.contentUID) {
        const content = this.settings[2].items.find((e) => e.id == uid);
        if (content) this.contents.push(content);
      }

      this.dialog = true;
      if (!item) await this.$nextTick(() => this.$refs.form.reset());
    },

    /**
     * 登録済機械のコピー
     * @param {object} item 選択した帳票情報
     */
    copyItem(item) {
      this.item = {
        id: this.item.id,
        sheetName: `${item.sheetName} - コピー`,
        tagUID: item.tagUID,
        contentUID: item.contentUID,
        updaterName: this.item.updaterName,
      };
      this.tags = [];
      this.contents = [];

      for (const uid of this.item.tagUID) {
        const tag = this.settings[1].items.find((e) => e.id == uid);
        if (tag) this.tags.push(tag);
      }

      for (const uid of this.item.contentUID) {
        const content = this.settings[2].items.find((e) => e.id == uid);
        if (content) this.contents.push(content);
      }
    },

    async registItem() {
      this.processing = true;

      const shop = this.$store.getters.getShop;
      const user = this.$store.getters.getUser;
      const isCreate = !this.item.id;

      const registId = this.item.id || db.collection("coreThermoCheckSheets").doc().id;
      const registData = {
        shopUID: shop.shopUID,
        sheetName: this.item.sheetName,
        tagUID: this.tags.map((e) => e.id),
        contentUID: this.contents.map((e) => e.id),
        updatedAt: new Date(),
        updaterName: user.name,
      };

      if (isCreate) registData.createdAt = new Date();

      const isSuccess = await this.updateDB({
        method: isCreate ? "set" : "update",
        collection: "coreThermoCheckSheets",
        docId: registId,
        data: registData,
      });

      this.processing = false;
      if (isSuccess) this.dialog = false;
    },
  },
};
</script>

<style scoped>
.asterisk {
  margin-left: 2px;
  color: red;
  font-weight: 700;
}

::v-deep .v-data-table .v-data-table__wrapper table thead tr th {
  height: 48px;
  background-color: #fafafa;
}

::v-deep .v-list-item {
  min-height: 36px !important;
}

::v-deep .v-list-item__content {
  padding: 0;
}

::v-deep .v-list-item__title {
  font-size: 0.8rem;
}
</style>
