var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-2 pa-sm-4",attrs:{"color":"#f5f5f5"}},[_c('v-card-title',{staticClass:"pa-4 d-flex align-baseline text-subtitle-1 text-sm-h6"},[_vm._v(" "+_vm._s(_vm.item.id ? "帳票編集" : "帳票登録")+" "),_c('span',{staticClass:"px-4 red--text text-caption"},[_vm._v("*必須項目")])]),_c('v-card-text',{staticClass:"px-0 pb-2"},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"px-4 pb-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"10","sm":"9"}},[_c('v-text-field',{attrs:{"rules":[_vm.required, _vm.duplicated]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("帳票名"),_c('span',{staticClass:"asterisk"},[_vm._v("*")])]},proxy:true}]),model:{value:(_vm.item.sheetName),callback:function ($$v) {_vm.$set(_vm.item, "sheetName", $$v)},expression:"item.sheetName"}})],1),_c('v-col',{staticClass:"px-2 px-sm-4",attrs:{"align-self":"center"}},[_c('MenuSheetCopy',{attrs:{"items":_vm.settings[0].items},on:{"select":_vm.copyItem}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-1",attrs:{"cols":"6"}},[_c('v-card',[_c('v-data-table',{class:_vm.$vuetify.breakpoint.xs ? 'table--xs' : '',attrs:{"headers":[
                  { text: 'グループ名', value: 'tagName' },
                  { text: '', value: 'action', align: 'end', class: 'pr-1', width: 96 } ],"items":_vm.tags,"items-per-page":-1,"loading-text":"読込中","no-data-text":"データがありません","disable-sort":"","fixed-header":"","hide-default-footer":"","mobile-breakpoint":null,"height":"40vh","dense":""},scopedSlots:_vm._u([{key:"header.action",fn:function(ref){return [_c('MenuSheetPartsSelect',{attrs:{"items":_vm.settings[1].items,"registeredItems":_vm.tags,"keyValue":"tagName"},on:{"select":function($event){return _vm.tags.push.apply(_vm.tags, $event)}}})]}},{key:"body",fn:function(ref){
                var items = ref.items;
return [_c('draggable',{attrs:{"tag":"tbody","handle":".drag","animation":"200"},model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}},_vm._l((items),function(item,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(item.tagName))]),_c('td',{staticClass:"pr-2"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.tags.splice(i, 1)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1),_c('v-btn',{staticClass:"ml-2 drag",staticStyle:{"cursor":"move"},attrs:{"icon":"","small":""}},[_c('v-icon',[_vm._v("mdi-drag-horizontal-variant")])],1)],1)])}),0)]}}],null,true)})],1)],1),_c('v-col',{staticClass:"pl-1",attrs:{"cols":"6"}},[_c('v-card',[_c('v-data-table',{class:_vm.$vuetify.breakpoint.xs ? 'table--xs' : '',attrs:{"headers":[
                  { text: '点検内容', value: 'content' },
                  { text: '', value: 'action', align: 'end', class: 'pr-1', width: 96 } ],"items":_vm.contents,"items-per-page":-1,"loading-text":"読込中","no-data-text":"データがありません","disable-sort":"","fixed-header":"","hide-default-footer":"","mobile-breakpoint":null,"height":"40vh","dense":""},scopedSlots:_vm._u([{key:"header.content",fn:function(ref){return [_c('span',[_vm._v("点検内容")]),_c('span',{staticClass:"asterisk"},[_vm._v("*")])]}},{key:"header.action",fn:function(ref){return [_c('MenuSheetPartsSelect',{attrs:{"items":_vm.settings[2].items,"registeredItems":_vm.contents,"keyValue":"content"},on:{"select":function($event){return _vm.contents.push.apply(_vm.contents, $event)}}})]}},{key:"body",fn:function(ref){
                var items = ref.items;
return [_c('draggable',{attrs:{"tag":"tbody","handle":".drag","animation":"200"},model:{value:(_vm.contents),callback:function ($$v) {_vm.contents=$$v},expression:"contents"}},_vm._l((items),function(item,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(item.content))]),_c('td',{staticClass:"pr-2"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.contents.splice(i, 1)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1),_c('v-btn',{staticClass:"ml-2 drag",staticStyle:{"cursor":"move"},attrs:{"icon":"","small":""}},[_c('v-icon',[_vm._v("mdi-drag-horizontal-variant")])],1)],1)])}),0)]}}],null,true)})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('div',{staticClass:"text-caption text--secondary"},[(_vm.updatedAt)?_c('p',{staticClass:"mb-0"},[_vm._v("更新日："+_vm._s(_vm.updatedAt))]):_vm._e(),(_vm.item.updaterName)?_c('p',{staticClass:"mb-0"},[_vm._v("更新者："+_vm._s(_vm.item.updaterName))]):_vm._e()]),_c('v-spacer'),_c('v-btn',{staticClass:"text--secondary",attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("閉じる")]),_c('v-btn',{attrs:{"color":"primary","depressed":"","loading":_vm.processing,"disabled":!_vm.valid || _vm.contents.length === 0 || _vm.processing},on:{"click":function($event){return _vm.registItem()}}},[_vm._v(" 登録 ")])],1)],1),_c('DialogMessage',{attrs:{"dialog":_vm.messageDialog,"message":_vm.message},on:{"close":function($event){_vm.messageDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }