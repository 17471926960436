var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"bottom":"","left":"","offset-y":"","min-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white",attrs:{"color":"primary","icon":"","elevation":"2"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{staticClass:"pa-2"},[_c('v-card-title',{staticClass:"pa-4 text-subtitle-2 text-sm-subtitle-1"},[_vm._v(" "+_vm._s(_vm.keyValue == "tagName" ? "グループ" : "点検項目")+"一覧 "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.closeMenu()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"px-3 pb-3"},[_c('v-text-field',{staticClass:"text-body-2",class:_vm.$vuetify.breakpoint.xs ? 'input--xs' : '',attrs:{"prepend-inner-icon":"mdi-magnify","placeholder":((_vm.keyValue == 'tagName' ? 'グループ' : '点検項目') + "名で検索"),"clearable":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":[
        { text: '', value: 'selectIndex', class: 'px-0' },
        { text: 'すべて選択', value: _vm.keyValue } ],"items":_vm.items,"items-per-page":-1,"item-key":_vm.keyValue,"search":_vm.search,"no-results-text":"データが見つかりません","disable-sort":"","no-data-text":"データがありません","show-select":"","fixed-header":"","hide-default-footer":"","mobile-breakpoint":null,"height":"40vh","dense":""},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
      var props = ref.props;
return [_c('v-simple-checkbox',_vm._b({attrs:{"disabled":_vm.items.length == _vm.registeredItems.length},on:{"click":function($event){return _vm.checkAll()}}},'v-simple-checkbox',props,false))]}},{key:"item",fn:function(ref){
      var item = ref.item;
      var isSelected = ref.isSelected;
      var select = ref.select;
return [_c('tr',{class:_vm.isRegistered(item) ? 'grey lighten-2' : '',on:{"click":function($event){_vm.isRegistered(item) || select(!isSelected)}}},[_c('td',[_c('v-simple-checkbox',{attrs:{"value":isSelected,"disabled":_vm.isRegistered(item),"indeterminate":_vm.isRegistered(item)},on:{"input":function($event){return select($event)}}})],1),_c('td',{staticClass:"px-0 text--secondary",staticStyle:{"width":"18px"}},[(_vm.selected.some(function (e) { return e.id == item.id; }))?_c('span',[_vm._v(" "+_vm._s(_vm.selected.findIndex(function (e) { return e.id == item.id; }) + 1)+" ")]):_vm._e()]),_c('td',{class:_vm.isRegistered(item) ? 'text--secondary' : ''},[_vm._v(" "+_vm._s(item[_vm.keyValue])+" ")])])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-card-actions',{staticClass:"px-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":!_vm.selected.length},on:{"click":function($event){return _vm.selectItem()}}},[_vm._v("リストに追加")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }