var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"bottom":"","left":"","offset-y":"","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-text-box-search-outline")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{staticClass:"pa-2 pa-sm-4",attrs:{"color":"#f5f5f5"}},[_c('v-card-title',{staticClass:"pa-4"},[_c('span',{staticClass:"text-subtitle-2 text-sm-subtitle-1"},[_vm._v("登録済帳票からコピーする")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.closeMenu()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card',{staticClass:"pa-2",attrs:{"outlined":""}},[_c('v-data-table',{class:_vm.$vuetify.breakpoint.xs ? 'table--xs' : '',attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":-1,"item-key":"sheetName","disable-sort":"","no-data-text":"データがありません","show-select":"","single-select":"","fixed-header":"","hide-default-footer":"","mobile-breakpoint":null,"height":"40vh","dense":""},on:{"click:row":function (value, row) { return row.select(!row.isSelected); }},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"color":"primary","ripple":false,"value":isSelected},on:{"input":function($event){return select($event)}}})]}},{key:"item.tagCount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.tagUID.length))]}},{key:"item.contentCount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.contentUID.length))]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-card-actions',{staticClass:"pt-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":!_vm.selected.length},on:{"click":function($event){return _vm.selectItem()}}},[_vm._v("コピー")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }