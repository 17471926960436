<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card class="pa-2 pa-sm-4">
      <v-card-title class="pa-4 d-flex align-baseline text-subtitle-1 text-sm-h6">
        {{ item.id ? "点検項目編集" : "点検項目登録" }}
        <span class="px-4 red--text text-caption">*必須項目</span>
      </v-card-title>

      <v-row class="my-3 px-4" no-gutters>
        <v-col cols="12">
          <v-form ref="form" v-model="valid">
            <v-text-field v-model="content" :rules="[(v) => !!v || '必須項目です']" dense>
              <template #label>点検内容<span class="asterisk">*</span></template>
            </v-text-field>
          </v-form>
        </v-col>
      </v-row>

      <!-- 入力タイプの選択 -->
      <v-row class="mb-4" no-gutters align-content="start">
        <v-col class="mb-1 px-4 d-flex" cols="12">
          <span class="mr-8 text--primary">入力タイプ</span>
          <v-checkbox class="ma-0 pa-0" v-model="referable" label="前回結果を取得する" hide-details dense />
        </v-col>
        <v-col cols="12"><v-divider /></v-col>
        <v-col class="pt-4 d-flex" cols="12" style="height: 250px">
          <div class="pl-4 pr-6 py-2">
            <v-radio-group class="ma-0 pa-0" v-model="type" mandatory hide-details dense>
              <v-radio v-for="(t, i) in types" :key="i" :label="t.text" :value="t.value" />
            </v-radio-group>
          </div>
          <v-divider vertical />
          <div class="pl-6 pr-4 py-2" style="flex: 1">
            <template v-if="type == 'okng'">
              <p>OKかNGを選択します</p>
              <v-checkbox v-model="typeData[type].required" label="必須入力にする" hide-details dense />
            </template>
            <template v-if="type == 'checkbox'">
              <p>チェックボックスを表示します</p>
            </template>
            <template v-if="type == 'select'">
              <p>一覧からプルダウンで選択します</p>
              <v-checkbox v-model="typeData[type].required" label="必須入力にする" hide-details dense />
              <v-text-field
                v-model="typeData[type].text"
                label="選択肢を追加"
                append-icon="mdi-plus"
                @keydown.enter="typeData[type].items.push(typeData[type].text)"
                @click:append="typeData[type].items.push(typeData[type].text)"
              />
              <p class="mb-1 text-body-2">選択肢</p>
              <v-chip
                v-for="(e, i) in typeData[type].items"
                :key="i"
                close
                small
                @click:close="typeData[type].items.splice(i, 1)"
              >
                {{ e }}
              </v-chip>
            </template>
            <template v-if="type == 'text'">
              <p>テキストを入力できます</p>
              <v-checkbox v-model="typeData[type].required" label="必須入力にする" hide-details dense />
              <v-text-field class="text-body-2" v-model="typeData[type].placeholder" label="入力ヒント" />
            </template>
            <template v-if="type == 'number'">
              <p>テンキーを表示して数値を入力できます</p>
              <v-checkbox v-model="typeData[type].required" label="必須入力にする" hide-details dense />
              <v-checkbox v-model="typeData[type].isShowMinus" label="負数入力をする" hide-details dense />
              <div class="input-digit mt-4 d-flex">
                <v-text-field v-model="typeData[type].unit" label="単位" placeholder="例：kg" hide-details />
                <v-select v-model="typeData[type].digit" :items="[1, 2, 3, 4, 5]" label="整数の最大桁数" hide-details />
                <v-select v-model="typeData[type].decimalDigit" :items="[1, 2]" label="小数の最大桁数" hide-details />
              </div>
              <div class="my-2 pt-4 input-allowable d-flex">
                <InputNumber
                  :menuProps="{
                    offsetY: true,
                    transition: 'slide-y-transition',
                  }"
                  :value.sync="typeData[type].min"
                  label="下限"
                  :unit="typeData[type].unit"
                />
                <div class="px-1" />
                <InputNumber
                  :menuProps="{
                    offsetY: true,
                    transition: 'slide-y-transition',
                  }"
                  :value.sync="typeData[type].max"
                  label="上限"
                  :unit="typeData[type].unit"
                />
              </div>
              <p
                v-if="typeData[type].min != '' && typeData[type].max != '' && typeData[type].min > typeData[type].max"
                class="text-caption red--text"
              >
                下限≦上限で設定してください
              </p>
            </template>
            <template v-if="type == 'date'">
              <p>日付を入力できます</p>
              <v-checkbox v-model="typeData[type].required" label="必須入力にする" hide-details dense />
            </template>
            <template v-if="type == 'time'">
              <p>時間を入力できます</p>
              <v-checkbox v-model="typeData[type].currentTime" label="初期表示を現在の時刻にする" hide-details dense />
              <p class="mt-6 mb-2 text--secondary text-body-2">表示形式</p>
              <v-radio-group class="ma-0 pa-0" v-model="typeData[type].timeFormat" mandatory hide-details dense>
                <v-radio label="時分秒" value="HH:mm:ss" />
                <v-radio label="時分" value="HH:mm" />
                <v-radio label="分秒" value="mm:ss" />
              </v-radio-group>
            </template>
          </div>
        </v-col>
      </v-row>

      <!-- 表示例 -->
      <v-row no-gutters class="pb-8">
        <v-col class="px-4" cols="12"><p class="mb-1 text--primary">表示例</p></v-col>
        <v-col class="mb-4" cols="12"><v-divider /></v-col>
        <v-col
          class="px-4 d-flex text-body-2"
          :class="type == 'text' ? 'flex-column' : 'align-center'"
          cols="12"
          style="min-height: 38px"
        >
          <span class="pt-2 pb-1">{{ content ? content : "点検内容" }}</span>
          <span v-if="type == 'show'">：</span>
          <v-spacer v-if="type != 'show' && type != 'text'" />
          <div :class="type != 'text' ? 'ml-4' : ''">
            <InputResult justify="end" :type="type" :data="typeData[type]" @input="typeData[type].result = $event" />
          </div>
        </v-col>
        <v-col cols="12"><v-divider /></v-col>
      </v-row>

      <v-card-actions>
        <div class="text-caption text--secondary">
          <p class="mb-0" v-if="item.updatedAt">更新日：{{ formatDate(item.updatedAt, "YYYY/MM/DD") }}</p>
          <p class="mb-0" v-if="item.updaterName">更新者：{{ item.updaterName }}</p>
        </div>
        <v-spacer></v-spacer>
        <v-btn class="text--secondary" text @click="dialog = false">閉じる</v-btn>
        <v-btn color="primary" depressed :loading="processing" :disabled="isDisabledButton" @click="registItem()">
          登録
        </v-btn>
      </v-card-actions>
    </v-card>
    <DialogMessage :dialog="messageDialog" :message="message" @close="messageDialog = false" />
  </v-dialog>
</template>

<script>
import { db } from "@/plugins/firebase";
import calcDate from "cumin-common/src/mixins/calcDate";
import InputResult from "../organisms/InputResult";

export default {
  components: {
    InputResult,
  },
  props: {
    settings: Array,
    updateDB: Function,
  },
  mixins: [calcDate],
  data: () => ({
    dialog: false,
    valid: true,
    item: {},
    content: "",
    referable: false,
    type: "okng",
    types: [
      { text: "OK / NG", value: "okng" },
      { text: "チェックボックス", value: "checkbox" },
      // { text: "プルダウンで選択", value: "select" },
      { text: "テキスト入力", value: "text" },
      { text: "数値入力", value: "number" },
      { text: "日付入力", value: "date" },
      { text: "時間入力", value: "time" },
    ],
    typeData: { okng: { required: false } },
    processing: false,
    message: "",
    messageDialog: false,
  }),
  computed: {
    isDisabledButton() {
      if (!this.valid) return true;
      if (this.processing) return true;
      if (this.type == "number") {
        const item = this.typeData.number;
        return item.min != "" && item.max != "" && item.min > item.max;
      }
      return false;
    },
  },
  methods: {
    /**
     * ダイアログを開き、選択された行の情報を取得
     * @param {item} item
     */
    async open(item) {
      this.item = item ? JSON.parse(JSON.stringify(item)) : {};
      this.referable = item?.referable ? item.referable : false;
      this.content = item ? item.content : "";
      this.type = item ? item.type : "okng";
      this.typeData = {
        okng: {
          required: this.type == "okng" && item?.required,
          result: "",
        },
        checkbox: {
          result: "",
        },
        number: {
          required: this.type == "number" && item?.required,
          isShowMinus: item?.isShowMinus ? item.isShowMinus : false,
          unit: item?.unit ? item.unit : "",
          digit: item?.digit ? item.digit : 5,
          decimalDigit: item?.decimalDigit ? item.decimalDigit : 2,
          min: item?.min ? item.min : "",
          max: item?.max ? item.max : "",
          result: "",
        },
        select: {
          required: this.type == "select" && item?.required,
          text: "",
          items: item?.items ? item.items : [],
          result: "",
        },
        text: {
          required: this.type == "text" && item?.required,
          placeholder: item?.placeholder ?? "",
          result: "",
        },
        time: {
          timeFormat: item?.timeFormat ? item.timeFormat : "HH:mm:ss",
          currentTime: item?.currentTime ? item.currentTime : false,
          result: "0:00:00",
        },
        date: {
          required: this.type == "date" && item?.required,
          result: "",
        },
      };
      this.dialog = true;
      if (!this.item.id) this.$nextTick(() => this.$refs.form.reset());
    },

    async registItem() {
      this.processing = true;

      const shop = this.$store.getters.getShop;
      const user = this.$store.getters.getUser;
      const isCreate = !this.item.id;

      const registId = this.item.id || db.collection("coreThermoCheckContents").doc().id;
      const registData = {
        shopUID: shop.shopUID,
        content: this.content,
        referable: this.referable,
        type: this.type,
        updatedAt: new Date(),
        updaterName: user.name,
      };

      // 入力タイプ別に設定追加
      const data = this.typeData[this.type];
      registData.required = !!data.required;
      switch (this.type) {
        case "select":
          registData.items = data.items;
          break;
        case "text":
          registData.placeholder = data.placeholder;
          break;
        case "number":
          registData.isShowMinus = data.isShowMinus;
          registData.unit = data.unit;
          registData.digit = data.digit;
          registData.decimalDigit = data.decimalDigit;
          registData.min = data.min;
          registData.max = data.max;
          break;
        case "time":
          registData.currentTime = data.currentTime;
          registData.timeFormat = data.timeFormat;
          break;
      }

      if (isCreate) registData.createdAt = new Date();

      const isSuccess = await this.updateDB({
        method: isCreate ? "set" : "update",
        collection: "coreThermoCheckContents",
        docId: registId,
        data: registData,
      });

      this.processing = false;
      if (isSuccess) this.dialog = false;
    },
  },
};
</script>

<style scoped>
.asterisk {
  margin-left: 2px;
  color: red;
  font-weight: 700;
}

::v-deep .v-label {
  font-size: 14px;
}

::v-deep .v-radio .v-label {
  color: #000000de;
}

::v-deep .input-digit .v-input:nth-child(-n + 2) {
  margin-right: 8px;
}
</style>
