<template>
  <v-dialog v-model="dialog" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="white"
        v-bind="attrs"
        v-on="on"
        color="primary"
        icon
        elevation="2"
        :large="$vuetify.breakpoint.smAndUp"
      >
        <v-icon>mdi-table-eye</v-icon>
      </v-btn>
    </template>
    <v-card class="pa-2 pa-sm-4" color="#f8f8f8">
      <v-card-title class="pa-4 d-flex align-baseline">
        <span class="mr-5 text-subtitle-1 text-sm-h6">点検状況確認</span>
        <span class="text-caption text-sm-subtitle-1 text--secondary">
          {{ date }}
        </span>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-sheet class="px-4 pb-2" color="#f8f8f8">
        <div class="d-flex align-center" style="height: 50px">
          <v-autocomplete
            class="mr-4 text-body-2"
            v-model="groupUID"
            :items="groups"
            item-text="sheetName"
            item-value="groupUID"
            clearable
            placeholder="帳票を選択"
            no-data-text="送信済の帳票がありません"
            prepend-inner-icon="mdi-magnify"
            hide-details
            dense
            :style="{ 'max-width': $vuetify.breakpoint.xs ? '300px' : '400px' }"
          >
            <template #item="{ item }">
              <div class="py-2" :style="{ 'max-width': $vuetify.breakpoint.xs ? '300px' : '400px' }">
                <p>{{ item.sheetName }}</p>
                <p
                  class="grey--text text-caption text-truncate"
                  v-for="(tag, i) in item.tags.slice(0, 5)"
                  :key="`${tag.id}_${i}`"
                >
                  {{ tag.label }}：{{ tag.content }}
                </p>
              </div>
            </template>
          </v-autocomplete>
          <v-spacer></v-spacer>
          <template v-if="groupUID">
            <v-btn v-if="isShowTagInfo" icon @click="isShowTagInfo = false"><v-icon>mdi-chevron-up</v-icon></v-btn>
            <v-btn v-else icon @click="isShowTagInfo = true"><v-icon>mdi-chevron-down</v-icon></v-btn>
          </template>
        </div>
        <template v-if="groupUID && isShowTagInfo">
          <div no-gutters class="pb-2 text-caption">
            <p class="text-truncate" v-for="(tag, i) in groupItem.tags.slice(0, 5)" :key="`${tag.id}_${i}`">
              {{ tag.label }}：{{ tag.content }}
            </p>
          </div>
        </template>
      </v-sheet>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="results.filter((e) => e.groupUID == groupUID)"
          :items-per-page="-1"
          sort-by="sentAt"
          :disable-sort="$vuetify.breakpoint.xs"
          no-data-text="帳票を選択してください"
          fixed-header
          hide-default-footer
          :mobile-breakpoint="null"
          height="50vh"
          dense
        >
          <template v-slot:[`item`]="{ item }">
            <tr>
              <td>
                <p class="text-caption">{{ formatDate(item.sentAt, "HH:mm") }}</p>
                <p class="grey--text" v-if="$vuetify.breakpoint.smAndUp" style="font-size: 0.5rem">
                  {{ item.sender }}
                </p>
              </td>
              <td v-for="(c, i) in item.contents" :key="`${item.id}_${i}`">
                <v-icon v-if="c.type == 'checkbox' && c.checkResult">mdi-check</v-icon>
                <span v-if="c.type != 'checkbox'" :class="isNormalResult(c) ? '' : 'red--text font-weight-bold'">
                  {{ c.checkResult }}
                </span>
                <span v-if="c.checkResult && c.unit" class="ml-1">{{ c.unit }}</span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import calcDate from "cumin-common/src/mixins/calcDate";

export default {
  props: {
    results: Array,
    groups: Array,
    date: String,
  },
  mixins: [calcDate],
  data: () => ({
    dialog: false,
    groupUID: "",
    isShowTagInfo: false,
  }),
  computed: {
    /**
     * フィルター後の最新結果を取得
     * @return {object}
     */
    groupItem() {
      const item = this.results.find((item) => item.groupUID == this.groupUID);
      return item ? item : {};
    },

    /**
     * フィルターによってヘッダを作成
     * @return {array}
     */
    headers() {
      const xs = this.$vuetify.breakpoint.xs;
      const item = this.results.find((e) => e.groupUID == this.groupUID);
      return item
        ? [
            { text: "送信日時", value: "sentAt", width: 116 },
            ...item.contents.map((e) => ({ text: e.content, sortable: false })),
          ]
        : [
            { text: "帳票名", value: "sheetName" },
            { text: xs ? "結果" : "点検結果", value: "checkResult", width: xs ? 58 : 80 },
            { text: "送信日時", value: "sentAt", width: xs ? 44 : 100 },
            { text: "確認日時", value: "confirmedAt", width: xs ? 34 : 100 },
            { text: "承認日時", value: "approvedAt", width: xs ? 34 : 112 },
          ];
    },

    /**
     * OK/NG判定
     * @param {object} item
     * @return {boolean} 判定結果
     */
    isNormalResult() {
      return (item) => {
        if (item.checkResult == "") return true;
        if (item.type === "okng" && item.checkResult == "NG") return false;
        if (item.type === "number") {
          if (item.min != "" && item.min > item.checkResult) return false;
          if (item.max != "" && item.max < item.checkResult) return false;
        }
        return true;
      };
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
}

::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) .v-input__control .v-input__slot {
  padding: 0 8px;
  min-height: 36px;
}

::v-deep .v-text-field input {
  padding: 8px 0 4px;
}

::v-deep .v-text-field .v-input__prepend-inner {
  padding-right: 16px;
}

::v-deep .v-list-item:not(:last-child) {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

::v-deep .v-data-table--dense .v-data-table__wrapper table .v-data-table-header tr th {
  height: 48px !important;
  background-color: #f8f8f8;
}

.v-data-table:not(.v-data-table--mobile) :v-deep .v-data-table__wrapper table tbody tr {
  height: 42px !important;
}

.v-data-table :v-deep .v-data-table__wrapper table thead tr th {
  white-space: pre-wrap;
}

::v-deep .v-data-table__wrapper table thead tr th {
  padding: 0 4px;
  word-break: keep-all;
}
::v-deep .v-data-table__wrapper table tbody tr td {
  padding: 0 4px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::v-deep .v-data-table__wrapper table thead tr th:first-child,
::v-deep .v-data-table__wrapper table tbody tr td:first-child {
  padding-left: 16px;
}
::v-deep .v-data-table__wrapper table thead tr th:last-child,
::v-deep .v-data-table__wrapper table tbody tr td:last-child {
  padding-right: 16px;
}
</style>
