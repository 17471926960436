<template>
  <v-container class="pa-2 pa-sm-4" fluid style="height: 100%">
    <v-row class="px-4" no-gutters style="height: 60px">
      <v-col cols="4" align-self="center">
        <span class="text-subtitle-1 text-sm-h6">点検結果一覧</span>
      </v-col>
      <v-col cols="5" sm="4" align-self="center">
        <MenuDatePicker
          :date="displayDate"
          @update="
            displayDate = $event;
            reloadList();
          "
        />
      </v-col>
      <v-col class="text-end" align-self="center" cols="3" sm="4">
        <DialogExportResult serviceName="中心温度" :properties="exportProperties" :selectedDate="displayDate" />
      </v-col>
    </v-row>

    <!-- 点検結果一覧 -->
    <v-row class="mb-4" no-gutters>
      <v-col>
        <v-card>
          <v-sheet class="px-2" color="#f8f8f8">
            <div class="d-flex align-center" style="height: 50px">
              <v-autocomplete
                class="mr-4 text-body-2"
                v-model="groupUID"
                :items="groups"
                item-text="sheetName"
                item-value="groupUID"
                clearable
                placeholder="帳票を検索"
                no-data-text="送信済の帳票がありません"
                prepend-inner-icon="mdi-magnify"
                outlined
                background-color="white"
                hide-details
                dense
                :style="{ 'max-width': $vuetify.breakpoint.xs ? '300px' : '400px' }"
              >
                <template #item="{ item }">
                  <div class="py-2" :style="{ 'max-width': $vuetify.breakpoint.xs ? '300px' : '400px' }">
                    <p>{{ item.sheetName }}</p>
                    <p
                      class="grey--text text-caption text-truncate"
                      v-for="(tag, i) in item.tags.slice(0, 5)"
                      :key="`${tag.id}_${i}`"
                    >
                      {{ tag.label }}：{{ tag.content }}
                    </p>
                  </div>
                </template>
              </v-autocomplete>
              <v-spacer></v-spacer>
              <div v-if="groupUID && $vuetify.breakpoint.smAndUp" class="mr-4 text-caption text--secondary">
                <p v-if="getGroupItem.confirmedAt">
                  確認日時：{{ formatDate(getGroupItem.confirmedAt, "MM/DD HH:mm") }} ({{ getGroupItem.confirmerName }})
                </p>
                <p v-if="getGroupItem.approvedAt">
                  承認日時：{{ formatDate(getGroupItem.approvedAt, "MM/DD HH:mm") }} ({{ getGroupItem.approverName }})
                </p>
              </div>
              <template v-if="groupUID">
                <v-btn v-if="isShowTagInfo" icon @click="isShowTagInfo = false"><v-icon>mdi-chevron-up</v-icon></v-btn>
                <v-btn v-else icon @click="isShowTagInfo = true"><v-icon>mdi-chevron-down</v-icon></v-btn>
              </template>
            </div>
            <template v-if="groupUID && isShowTagInfo">
              <div no-gutters class="pa-2 text-caption" style="height: 116px">
                <p class="text-truncate" v-for="(tag, i) in getGroupItem.tags.slice(0, 5)" :key="`${tag.id}_${i}`">
                  {{ tag.label }}：{{ tag.content }}
                </p>
              </div>
              <div v-if="$vuetify.breakpoint.xs" class="px-2 pb-2 text-caption text--secondary">
                <p v-if="getGroupItem.confirmedAt">
                  確認日時：{{ formatDate(getGroupItem.confirmedAt, "MM/DD HH:mm") }} ({{ getGroupItem.confirmerName }})
                </p>
                <p v-if="getGroupItem.approvedAt">
                  承認日時：{{ formatDate(getGroupItem.approvedAt, "MM/DD HH:mm") }} ({{ getGroupItem.approverName }})
                </p>
              </div>
            </template>
          </v-sheet>
          <v-divider v-if="groupUID"></v-divider>
          <v-data-table
            v-model="selected"
            :headers="getHeaders"
            :items="groupUID ? items.filter((e) => e.groupUID == groupUID) : items"
            :items-per-page="-1"
            :loading="loading"
            sort-by="sentAt"
            :disable-sort="$vuetify.breakpoint.xs"
            :show-select="showSelect && !groupUID"
            loading-text="読込中"
            no-data-text="点検結果がありません"
            fixed-header
            hide-default-footer
            checkbox-color="primary"
            :mobile-breakpoint="null"
            :height="getTableHeight"
            dense
            @click:row="$refs.checkResultDialog.openDialog($event)"
          >
            <template v-if="groupUID" v-slot:[`item`]="{ item }">
              <tr @click="$refs.checkResultDialog.openDialog(item)">
                <td>
                  <p class="text-caption">{{ formatDate(item.sentAt, "HH:mm") }}</p>
                  <p class="grey--text" v-if="$vuetify.breakpoint.smAndUp" style="font-size: 0.5rem">
                    {{ item.sender }}
                  </p>
                </td>
                <td v-for="(c, i) in item.contents" :key="`${item.id}_${i}`">
                  <v-icon v-if="c.type == 'checkbox' && c.checkResult">mdi-check</v-icon>
                  <span v-if="c.type != 'checkbox'" :class="isNormalResult(c) ? '' : 'red--text font-weight-bold'">
                    {{ c.checkResult }}
                  </span>
                  <span v-if="c.checkResult && c.unit" class="ml-1">{{ c.unit }}</span>
                </td>
              </tr>
            </template>
            <template v-slot:[`item.tags`]="{ item }">
              <div class="py-1">
                <p
                  class="grey--text text-caption text-truncate"
                  v-for="(tag, i) in item.tags.filter((e) => !!e.content).slice(0, 5)"
                  :key="`${tag.id}_${i}`"
                >
                  {{ tag.label }}：{{ tag.content }}
                </p>
              </div>
            </template>
            <template v-slot:[`item.checkResult`]="{ item }">
              <span :class="item.isNormalForReport ? '' : 'red--text font-weight-bold'">
                {{ item.isNormalForReport ? "OK" : "NG" }}
              </span>
              <v-icon
                v-if="comments.some((e) => e.relationCheckResult && e.relationCheckResult.uid == item.id)"
                class="ml-1"
              >
                mdi-comment-processing-outline
              </v-icon>
            </template>
            <template v-slot:[`item.sentAt`]="{ item }">
              <p class="text-caption">{{ formatDate(item.sentAt, "MM/DD HH:mm") }}</p>
              <p class="grey--text" v-if="$vuetify.breakpoint.smAndUp" style="font-size: 0.5rem">
                {{ item.sender }}
              </p>
            </template>
            <template v-slot:[`item.confirmedAt`]="{ item }">
              <template v-if="$vuetify.breakpoint.smAndUp">
                <p class="text-caption">{{ formatDate(item.confirmedAt, "MM/DD HH:mm") }}</p>
                <p class="grey--text" style="font-size: 0.5rem">{{ item.confirmerName }}</p>
              </template>
              <template v-else>{{ item.confirmerName ? "〇" : "" }}</template>
            </template>
            <template v-slot:[`item.approvedAt`]="{ item }">
              <template v-if="$vuetify.breakpoint.smAndUp">
                <p class="text-caption">{{ formatDate(item.approvedAt, "MM/DD HH:mm") }}</p>
                <p class="grey--text" style="font-size: 0.5rem">{{ item.approverName }}</p>
              </template>
              <template v-else>{{ item.approverName ? "〇" : "" }}</template>
            </template>
            <template v-slot:top>
              <DialogCheckResult ref="checkResultDialog" :comments="comments" @update="$refs.comments.loadComment()" />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- 特記事項・送信ボタン -->
    <div class="d-sm-flex align-center">
      <CardComments
        ref="comments"
        :date="displayDate"
        :isMonthly="false"
        serviceName="中心温度"
        @load="comments = $event"
      />
      <ButtonApproveResult
        :items="groupUID ? items.filter((e) => e.groupUID == groupUID) : selected"
        collection="coreThermoCheckResults"
        @reload="loadList()"
      />
    </div>

    <DialogMessage :dialog="messageDialog" :message="message" @close="messageDialog = false" />
  </v-container>
</template>

<script>
import calcDate from "cumin-common/src/mixins/calcDate";
import dbProcess from "cumin-common/src/mixins/dbProcess";
import DialogCheckResult from "../organisms/DialogCheckResult";
import DialogExportResult from "../organisms/DialogExportResult";

export default {
  components: {
    DialogCheckResult,
    DialogExportResult,
  },
  mixins: [calcDate, dbProcess],
  data: () => ({
    loading: false,
    displayDate: "",
    groups: [],
    groupUID: "",
    showSelect: false,
    isShowTagInfo: false,
    selected: [],
    items: [],
    comments: [],
    processing: false,
    message: "",
    messageDialog: false,
    exportProperties: [
      {
        sheetName: "中心温度点検結果",
        collection: "coreThermoCheckResults",
        resultKey: "contents",
        resultColumns: [
          { header: "点検内容", key: "content" },
          { header: "点検結果", key: "checkResult" },
        ],
        columns: [
          { header: "帳票名", key: "sheetName" },
          { header: "グループ", key: "tags" },
          { header: "点検結果", key: "isNormalForReport" },
          { header: "送信日時", key: "sentAt" },
          { header: "送信者", key: "sender" },
          { header: "確認日時", key: "confirmedAt" },
          { header: "確認者", key: "confirmerName" },
          { header: "承認日時", key: "approvedAt" },
          { header: "承認者", key: "approverName" },
        ],
      },
    ],
  }),
  created: function () {
    this.$emit("created");
    const queryDate = this.$route.query.date;
    this.displayDate = queryDate ? queryDate : this.calculateBusinessDate(new Date());

    const user = this.$store.getters.getUser;
    this.showSelect = user.authority == "approver" || user.authority == "confirmer";
  },
  activated: async function () {
    this.loading = true;
    await this.loadList();
    this.loading = false;
  },
  computed: {
    /**
     * テーブルの高さを取得
     * @return {number} 高さ
     */
    getTableHeight() {
      const bp = this.$vuetify.breakpoint;
      const offset = this.comments.length > 0 ? 186 : 60;
      const dividerHeight = this.groupUID ? 1 : 0;
      const tagHeight = this.groupUID && this.isShowTagInfo ? 116 : 0;
      const height = bp.height - (bp.xs ? 244 : 254) - offset - dividerHeight - tagHeight;
      return height <= 300 ? 300 : height;
    },

    /**
     * フィルターによってヘッダを作成
     * @return {array}
     */
    getHeaders() {
      const xs = this.$vuetify.breakpoint.xs;
      const item = this.items.find((e) => e.groupUID == this.groupUID);
      return item
        ? [
            { text: "送信日時", value: "sentAt", width: 116 },
            ...item.contents.map((e) => ({ text: e.content, sortable: false })),
          ]
        : [
            { text: "帳票名", value: "sheetName" },
            { text: "グループ名", value: "tags" },
            { text: xs ? "結果" : "点検結果", value: "checkResult", width: xs ? 58 : 80 },
            { text: "送信日時", value: "sentAt", width: xs ? 44 : 100 },
            { text: "確認日時", value: "confirmedAt", width: xs ? 34 : 100 },
            { text: "承認日時", value: "approvedAt", width: xs ? 34 : 112 },
          ];
    },

    /**
     * フィルター後の最新結果を取得
     * @return {object}
     */
    getGroupItem() {
      const item = this.items.find((item) => item.groupUID == this.groupUID);
      return item ? item : {};
    },

    /**
     * OK/NG判定
     * @param {object} item
     * @return {boolean} 判定結果
     */
    isNormalResult() {
      return (item) => {
        if (item.checkResult == "") return true;
        if (item.type === "okng" && item.checkResult == "NG") return false;
        if (item.type === "number") {
          if (item.min != "" && item.min > item.checkResult) return false;
          if (item.max != "" && item.max < item.checkResult) return false;
        }
        return true;
      };
    },
  },
  methods: {
    /**
     * DBから点検結果を取得
     */
    async loadList() {
      const shop = this.$store.getters.getShop;
      const startAt = new Date(this.displayDate + " 00:00:00");
      const endAt = new Date(this.displayDate + " 23:59:59");

      this.items = await this.getQueryDoc({
        collection: "coreThermoCheckResults",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
        order: [{ fieldPath: "registeredAt", directionStr: "asc" }],
        startAt: startAt,
        endAt: endAt,
      });

      // 送信日時を降順でソート
      this.items.sort((a, b) => {
        const timeA = new Date(a.sentAt.seconds * 1000);
        const timeB = new Date(b.sentAt.seconds * 1000);
        if (timeA > timeB) return -1;
        if (timeA < timeB) return 1;
        return 0;
      });

      // 帳票グループ一覧を作成
      this.groups = [];
      for (const result of this.items) {
        // すでに抽出済か
        if (this.groups.some((e) => e.groupUID == result.groupUID)) continue;

        // 未抽出の場合、同じ点検結果の数を集計
        const checkCount = this.items.filter((e) => e.groupUID == result.groupUID).length;
        this.groups.push({ ...result, checkCount });
      }
    },

    /**
     * 点検項目、点検一覧、コメントの再読み込み
     */
    async reloadList() {
      this.loading = true;
      this.groupUID = "";
      await this.loadList();
      this.$refs.comments.loadComment();
      this.loading = false;
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
}

::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) .v-input__control .v-input__slot {
  padding: 0 8px;
  min-height: 36px;
}

::v-deep .v-text-field input {
  padding: 8px 0 4px;
}

::v-deep .v-text-field .v-input__prepend-inner {
  padding-right: 16px;
}

.v-select-list ::v-deep .v-list-item:not(:last-child) {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

::v-deep .v-data-table--dense .v-data-table__wrapper table .v-data-table-header tr th {
  height: 48px !important;
  background-color: #f8f8f8;
}

.v-data-table:not(.v-data-table--mobile) :v-deep .v-data-table__wrapper table tbody tr {
  height: 42px !important;
}

.v-data-table :v-deep .v-data-table__wrapper table thead tr th {
  white-space: pre-wrap;
}

::v-deep .v-data-table__wrapper table thead tr th {
  padding: 0 4px;
  word-break: keep-all;
}
::v-deep .v-data-table__wrapper table tbody tr td {
  padding: 0 4px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::v-deep .v-data-table__wrapper table thead tr th:first-child,
::v-deep .v-data-table__wrapper table tbody tr td:first-child {
  padding-left: 16px;
}
::v-deep .v-data-table__wrapper table thead tr th:last-child,
::v-deep .v-data-table__wrapper table tbody tr td:last-child {
  padding-right: 16px;
}

::v-deep .v-input--selection-controls__ripple {
  display: none;
}
</style>
